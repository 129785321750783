import * as React from "react";
import "./SponsorItemm.css";
import {
  useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatDateTime } from "views/site/helpers";
import imageHolder from "assets/images/imageHolder.png";

type Props = {
  sponsor: any;
  removeCart: (sponsor: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
};

export const SponsorItemm: React.FC<Props> = ({
  sponsor,
  selected,
  removeCart,
  handleShow,
}) => {
  const history = useHistory();
  const textColor = useColorModeValue("navy.700", "white");
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  return (
    <div>
      <a
        onClick={() => {
          localStorage.setItem("sponsorItem", JSON.stringify(sponsor));

          if (user.sponsorId === sponsor.id) {
            history.push(`/site/sponsor_profile?id=${sponsor?.id}&zIx=890`, {
              itemData: "qqsad",
              update: true,
            });
          } else {
            history.push(`/site/sponsor_details?id=${sponsor?.id}&zIx=890`, {
              itemData: "qqsad",
              update: true,
            });
          }
        }}
        className="relative bg-white block overflow-hidden rounded-lg border border-gray-100 p-4 sm:p-6 lg:p-8"
      >
        <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>

        <div className="sm:flex sm:justify-between sm:gap-4">
          <div>
            <h3 className="text-lg font-bold text-gray-900 sm:text-xl">
              {sponsor.isCompany
                ? sponsor.companyName
                : sponsor.anonymous
                ? "anonymous user"
                : sponsor.sponsorName === ""
                ? "anonymous"
                : sponsor.sponsorName}
            </h3>

            <p className="mt-1 text-xs font-medium text-gray-600">
              {sponsor?.industry}
            </p>
          </div>

          <div className="hidden sm:block sm:shrink-0">
            <img
              alt=""
              src={sponsor.coverImage ? sponsor.coverImage : imageHolder}
              //"https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
              className="size-16 rounded-lg object-cover shadow-sm"
            />
          </div>
        </div>

        <div className="mt-4">
          <p className="text-pretty text-sm text-gray-500">
            {sponsor?.region} {sponsor?.district}
            {""}
            {sponsor?.ward}
            {""}
            {sponsor?.mtaa}
          </p>
        </div>

        <dl className="mt-6 flex gap-4 sm:gap-6">
          <div className="flex flex-col-reverse">
            <dt className="text-sm font-medium text-gray-600">Joined</dt>
            <dd className="text-xs text-gray-500">
              {formatDateTime(sponsor?.createdAt)}
            </dd>
          </div>

          <div className="flex flex-col-reverse">
            <dt className="text-sm font-medium text-gray-600">Funded</dt>
            <dd className="text-xs text-gray-500">2 events</dd>
          </div>
        </dl>
      </a>
    </div>
  );
};

import * as React from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import "./BandaJamiiPost.css";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Button, FormLabel } from "@chakra-ui/react";
import imageHolder from "assets/images/imageHolder.png";


type Props = {
  cart: any;
  removeCart: (cart: ICart) => void;
  addToContent?: (cart: IBanda, value: number, typeIn: string) => void;
  removeFromList: (cart: IBanda) => void;
  selectedFrequency: (value: number, cart: IBanda) => void;
  isPromoted: (value: boolean, cart: IBanda) => void;
  promotedValue?: boolean;
  selectedPrice: (value: number, cart: IBanda) => void;
  defSelectedPrice: number;
};

export const SelectedBandaUmiza: React.FC<Props> = ({
  cart,
  removeFromList,
  selectedFrequency,
  selectedPrice,
  isPromoted,
  promotedValue,
  defSelectedPrice,
  removeCart,
  addToContent,
}) => {
  const dispatch: Dispatch<any> = useDispatch();

  // const navigate = useNavigate();
  const [frequency, setFrequency] = React.useState(cart.frequency);

  const [amount, setAmount] = React.useState(parseInt(cart.price));
  const [isExpanded, setIsExpanded] = React.useState(false);

  // Change handler for the Amount field
  const handleAmountChange = (e: any) => {
    const amountValue = parseInt(e.target.value);
    setAmount(amountValue);
  };

  React.useEffect(() => {
    selectedPrice(amount, cart);
  }, [amount]);

  React.useEffect(() => {
    if(cart.type === "banda"){

    if(!(JSON.parse(cart.dates).length > 0)){
      removeFromList(cart)
    }
  }
  }, [cart]);

  // const decreaseFrequency = () => {
  //   setFrequency((prevFrequency: number) =>
  //     prevFrequency > 0 ? prevFrequency - 1 : 0
  //   );
  //   selectedFrequency(frequency)
  // };

  // console.log(cart);

  const [promoteChecked, setPromoteChecked] = React.useState(cart.isPromoted);
  const getTypeColor = (type: any) => {
    switch (type) {
      case "busket":
        return "#ffc2d1";
      case "banda":
        return "blue";
      case "region":
        return "#c7f9cc";
      case "district":
          return "gray";  
      default:
        return "#ade8f4"; // banda is undefined
    }
  };
  return (
   <div>
    {/* <div
          style={{
            marginTop: 20,
            height: 50,
            backgroundColor: getTypeColor(cart.type), // Function to determine color based on type
            width: 8,
          }}
        /> */}
    <article className="rounded-xl border-2 border-gray-100 bg-white">
    
  <div className="flex items-start gap-4 p-4 sm:p-6 lg:p-8">
    <a href="#" className="block shrink-0">
      <img
        alt=""
        src={imageHolder}
        className="size-14 rounded-lg object-cover"
      />
    </a>

    <div>
      <h3 className="font-medium sm:text-lg">
      <a href="#" className="hover:underline"> {cart.name ? cart.name.split('_')[0] : ""}</a>
      <a href="#" className="hover:underline"> {cart.name ? cart.name.split('_')[0] : ""}</a>
      </h3>

      <p className="small text-lg font-semibold">
            {(!cart.ward && !cart.name )? cart.district.split('_')[0]+`${cart.ward ? " , ":""}` :""}
            </p>
            <p className="small text-lg font-semibold">
            {(!cart.ward && !cart.name && !cart.district )? cart.region: ""}            </p>




            <p className="line-clamp-2 text-sm text-gray-700">{cart.region? cart.region+`${cart.district ? " , ":""}`: ""}{cart.district? cart.district.split('_')[0]+`${cart.ward ? " , ":""}` :""}{cart.ward ? cart.ward.split('_')[0] : ""}{", "}</p>
            {cart.type === "banda" &&  <h1 className="small">Reservations made : {JSON.parse(cart.dates).length}</h1>}


      <div className="mt-2 sm:flex sm:items-center sm:gap-2">
        <div className="flex items-center gap-1 text-gray-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
            />
          </svg>

          <p className="text-xs">14 assigned</p>
        </div>

        <span className="hidden sm:block" aria-hidden="true">&middot;</span>

        
        {!(cart.type === "busket") && (
        <div
          style={{
            display: "flex",
            marginTop: -18,
            marginLeft: 30,
            height: 20,
            flexDirection: "row",
          }}
        >
          <FormLabel
            style={{
              marginTop: 10,
              fontSize:12,
            }}
          >
            Promote<input
            type="checkbox"
            style={{
              marginLeft: 10,
            }}
          
            name="contentOwnership"
            checked={promoteChecked}
            onChange={() => {
              const dt = promoteChecked;
              setPromoteChecked(!promoteChecked);
              isPromoted(!dt, cart);
            }}
          />
          </FormLabel>
          
        </div>
      )}
      </div>
    </div>
  </div>


  {true && <div
        style={{
          //marginTop: 0,
          marginBottom: -10,
          // backgroundColor:"yellow",
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        
        <div
          style={{
            marginTop: 0,
            marginBottom: 4,
            height: 50,
            width: "40%",
            left: 0,
            paddingLeft: 20,
            top: 0,
            display: "flex",
            flexDirection: "column",
            // backgroundColor: "pink",
          }}
        >
          <div>
        

            {!(cart.type === "busket") && cart.type !== "banda" && (
              <h1
                style={{
                  fontSize: 12,
                  margin: 0,
                  padding: 0,
                  fontWeight: "bold",
                }}
              >
                {cart.frequency}{" "} Banda
              </h1>
            )}

            {!(cart.type === "busket") && cart.type !== "banda" && (
              <div
                style={{
                  // position:"absolute",
                  // top:10,
                  marginLeft: "40%",
                  marginTop: -25,
                  height: 30,
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <button
                  style={{
                    margin: 0,
                    // backgroundColor:"#333",
                    borderRadius: 5,
                    marginRight: 20,
                    marginLeft: 20,
                    padding: 0,
                  }}
                  className="small"
                  onClick={() => {
                    console.log(frequency);

                    setFrequency((prevFrequency: number) =>
                      prevFrequency > 0 ? prevFrequency - 1 : 0
                    );
                    selectedFrequency(frequency - 1, cart);
                  }}
                >
                  <FaMinus />
                </button>
                <button
                  style={{
                    margin: 0,
                    // backgroundColor:"#333",
                    borderRadius: 5,
                    padding: 0,
                  }}
                  onClick={() => {
                    console.log(frequency);
                    
                    setFrequency((prevFrequency: number) => prevFrequency + 1);
                    selectedFrequency(frequency + 1, cart);
                  }}
                >
                  <FaPlus />
                </button>
              </div>
            )}
          </div>
        </div>
        {cart.type === "busket" && (
          <>
            <input
              type="number"
              value={amount}
              onChange={handleAmountChange}
              name="amount"
              style={{
                height: 30,
                borderRadius: 4,
                border: "1px solid #7f7f7f",
                color:"black",
              }}
            />
            <p style={{ paddingLeft: 5 }}> Amount</p>
          </>
        )}
        
      </div>}


  <div  onClick={() => {
            console.log("cacsadcasdcacd")
            console.log("cacsadcasdcacd")
            console.log("cacsadcasdcacd")
            console.log("cacsadcasdcacd")
            console.log("cacsadcasdcacd")
            removeFromList(cart);
          }}
           className="flex justify-end">
    <strong
      className="-mb-[2px] -me-[2px] inline-flex items-center gap-1 rounded-ee-xl rounded-ss-xl bg-red-600 px-3 py-1.5 text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="size-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
        />
      </svg>

      <span className="text-[10px] font-medium sm:text-xs">Remove</span>
    </strong>
  </div>

 
</article>



   </div> 
  );
};

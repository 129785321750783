import * as React from "react";
import { Dispatch } from "redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "./Cart.css";
import { addCart } from "../../redux.store/cart/actionCreators";
import { useState } from "react";
import {
  useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import image1 from "assets/images/haki.jpg";

type Props = {
  cart: any;
  removeCart: (cart: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
};

export const Cart: React.FC<Props> = ({
  cart,
  selected,
  removeCart,
  handleShow,
}) => {
  const history = useHistory();

  
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );

  
  const idExists = carts.some((item: any) => item.id === cart.id);

  return (
    <div className="my-5 sm:my-0">
      <a
        onClick={() => {
          if (user.sponsorStatus === "sponsor" && !(user.mode === "banda")) {
            if (user.loginStatus || true) {
              localStorage.setItem("contentItem", JSON.stringify(cart));
              localStorage.setItem(
                "activeCart",
                JSON.stringify({ item: cart, isPromo: false })
              );

              history.push(`/site/content?id=${cart?.id}&zIx=890`, {
                itemData: "qqsad",
                update: true,
                state: {
                  itemId: cart?.id,
                  update: idExists, //exist now update
                  isPromo: false,
                },
              });
            } else {
              history.push("/auth/sign-in");
            }
          } else {
            localStorage.setItem("contentItem", JSON.stringify(cart));

            history.push(`/site/content?id=${cart?.id}&zIx=890`, {
              itemData: cart.id,
              update: idExists, //exist now update
            });
          }
        }}
        className="group w-full relative block bg-black"
      >
        <img
          alt=""
          src={
            cart?.coverImage?.trim() === "" || !cart?.coverImage
              ? image1
              : cart?.coverImage
            // "https://images.unsplash.com/photo-1603871165848-0aa92c869fa1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80"
          }
          className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
        />
          <div className="absolute inset-0 bg-black opacity-50"/>

        <div className="relative p-4 sm:p-6 lg:p-8">
          <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
            {cart?.author?.username}
          </p>

          <p className="text-xl font-bold text-white sm:text-2xl">
            {cart?.title}
          </p>

          <div className="mt-32 sm:mt-48 lg:mt-64">
            <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
              <p className="text-sm text-white">
                {" "}
                {cart?.events
                  ? `In  ${cart?.events.length}${
                      cart?.events.length > 0 ? "+" : ""
                    }  ${cart?.events.length > 0 ? "events" : "event"}`
                  : ""}
              </p>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

import { useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  useColorModeValue,
  SimpleGrid,
  Wrap,
} from "@chakra-ui/react";

// Custom components

// Assets
import Pic4 from "assets/images/haki.jpg";
import Pic5 from "assets/images/imageHolder.png";
import Pic6 from "assets/images/imageHolder.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
import { removeCart } from "redux.store/cart/actionCreators";
import { shallowEqual, useSelector } from "react-redux";
import { CartAdd } from "components_app/CartAdds/Cart";
import { useMyContentsListQuery } from "generated/graphql";
import Banner from "../exploreBanda/components/Banner";
import BannerSide from "../exploreBanda/components/BannerSide";

export const filtersLabels = [
  { mode: "Afya", label: "Afya" },
  { mode: "Vocational", label: "Vocational" },
  // { mode: "Recently", label: "Recently" },
  { mode: "Legal", label: "Legal" },
  { mode: "Health and Wellness", label: "Health and Wellness" },
  { mode: "Community Development", label: "Community Development" },
  { mode: "Food and Nutrition", label: "Food and Nutrition" },
  { mode: "Entertainment", label: "Entertainment" }, // music and art
  { mode: "Entrepreneurship", label: "Entrepreneurship" },
  { mode: "Sports", label: "Sports" },
  { mode: "Spiritual", label: "Spiritual" },
  { mode: "Education", label: "Education" },
  { mode: "Environment", label: "Environment" },
  { mode: "Technology and ICT", label: "Technology and ICT" },
  { mode: "Others", label: "Others" },
];

export const sampleData2 = [
  {
    id: "as2nsdd",
    name: "Jipatie nguo za watoto",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic4,
    videos: ["hjgj", "fjjj"],
    title: "Nguo za kisasa",
    type: "ADD",

    owner: "VunjaBei",
    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2n087sdd",
    name: "Elimu ya kisasa",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic5,
    videos: ["hjgj", "fjjj"],
    title: "Shule ya Kisasa ",
    type: "ADD",

    owner: "St Marys",
    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },

  {
    id: "as2n875sdd",
    name: "Jipatie Matunda Mwaka mzima",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic6,
    videos: ["hjgj", "fjjj"],
    title: "Matunda mwaka mzima",
    type: "ADD",

    owner: "Matunda fresh Company",
    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
];

export default function MyAdds() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const [data, setData] = useState(sampleData2);
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    console.log("information");
    console.log("information");
    return setShow(true);
  };

  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(18);
  const [category, setCategory] = useState("all");

  const totalPages = 10; // You can set this to the total number of pages available

  // Handle the previous page
  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Handle the next page
  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  // Handle page number click
  const handlePageClick = (pageNum: number) => {
    setPage(pageNum);
  };

  const contentData = useMyContentsListQuery({
    fetchPolicy: "network-only",
    variables: {
      getMyContentInput: {
        userId: user?.id,
        contentType: "PROMO",
        page,
        pageSize,
        category,
      },
    },
  });

  console.log(contentData);
  console.log(contentData);

  return (
    <div className="w-full flex bg-gray-50 justify-center flex-col items-center ">
      <div className="w-full flex max-w-screen-xl flex-col items-center ">
        <div className="w-full  max-w-screen-xl">
          <Grid
            mb={10}
            templateColumns={{
              base: "1fr",
              lg: "repeat(2, 1fr)",
              //  "2xl": "1.34fr 1.62fr 1fr",
            }}
            gap={{ base: "20px", xl: "20px" }}
          >
            <Banner />
            <BannerSide />
          </Grid>

          <Grid
            mb="20px"
            gridTemplateColumns={{
              xl: "repeat(1, 1fr)",
              // "2xl": "1fr 0.46fr"
            }}
            gap={{ base: "20px", xl: "20px" }}
            display={{ base: "block", xl: "grid" }}
          >
            <Flex
              flexDirection="column"
              gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
            >
              <Flex direction="column">
                <h2
               className="text-4xl font-black "
            >Choose from your uploaded adds</h2>
                <Flex
                  mt="45px"
                  mb="20px"
                  justifyContent="space-between"
                  direction={{ base: "column", md: "row" }}
                  align={{ base: "start", md: "center" }}
                >
                  <Flex
                    align="center"
                    me="20px"
                    ms={{ base: "24px", md: "0px" }}
                    mt={{ base: "20px", md: "0px" }}
                  >
                    <Wrap
                      spacing={3}
                      style={{
                        marginTop: 0,
                        // backgroundColor: "red",
                        borderRadius: 20,
                        width: "100%",
                      }}
                    ></Wrap>
                  </Flex>
                </Flex>
                <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px">
                  {contentData?.data?.myContentsList?.map((cart: any) => {
                    const existingCart = carts.find(
                      (item: ICart) => item.id === cart.id
                    );
                    return (
                      <CartAdd
                        key={cart.id}
                        cart={cart}
                        removeCart={removeCart}
                        selected={existingCart}
                        handleShow={handleShow}
                      />
                    );
                  })}
                </SimpleGrid>
              </Flex>
            </Flex>
          </Grid>
        </div>
      </div>
    </div>
  );
}

import { useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Text,
  Button,
  useColorModeValue,
  SimpleGrid,
  WrapItem,
  Wrap,
  Card,
  Select,
  Input,
  Icon,
} from "@chakra-ui/react";

// Custom components

// Assets
import Pic1 from "assets/images/imageHolder.png";
import Pic2 from "assets/images/imageHolder.png";
import Pic3 from "assets/images/imageHolder.png";
import Pic4 from "assets/images/haki.jpg";
import Pic5 from "assets/images/imageHolder.png";
import Pic6 from "assets/images/imageHolder.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
import { removeCart } from "redux.store/cart/actionCreators";
import { Cart } from "components_app/Cart_Components/Cart";
import { shallowEqual, useSelector } from "react-redux";
import { useContentListQuery } from "generated/graphql";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import Banner from "./components/Banner";
import BannerSide from "./components/BannerSide";
import DrawerFilter from "../exploreBanda/components/draw";

export const filtersLabels = [
  { mode: "Afya", label: "Afya" },
  { mode: "Vocational", label: "Vocational" },
  // { mode: "Recently", label: "Recently" },
  { mode: "Legal", label: "Legal" },
  { mode: "Health and Wellness", label: "Health and Wellness" },
  { mode: "Community Development", label: "Community Development" },
  { mode: "Food and Nutrition", label: "Food and Nutrition" },
  { mode: "Entertainment", label: "Entertainment" }, // music and art
  { mode: "Entrepreneurship", label: "Entrepreneurship" },
  { mode: "Sports", label: "Sports" },
  { mode: "Spiritual", label: "Spiritual" },
  { mode: "Education", label: "Education" },
  { mode: "Environment", label: "Environment" },
  { mode: "Technology and ICT", label: "Technology and ICT" },
  { mode: "Others", label: "Others" },
];

export const sampleData2 = [
  {
    id: "asb",
    images: ["../../../assets/images/content.png", "khg"],
    image: Pic1,
    videos: ["hjgj", "fjjj"],
    title: "Elimu ya Afya",
    name: "Mazingira",
    type: "CONTENT",

    owner: "By Balozi ya Ufaransa",
    description: "descriptiongfch",
    body: "Afya ya uzazi, akili na mazingira",
    othersMetaData: ["tracking data"],
    price: 0,
    currency: "gj",
    discount: "5%",
    //add fee
    //status in metadata
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "ansd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic2,
    videos: ["hjgj", "fjjj"],
    title: "Semina ya vijana",
    owner: "Haki Elimu",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Vijana na kujituma",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "an90sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic3,
    videos: ["hjgj", "fjjj"],
    title: "Ujasiliamali",
    owner: "Wizara ya Afya",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Kuhusu kujizalishia kipato",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2nsdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic4,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2n087sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic5,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },

  {
    id: "as2n875sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic6,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
];

export default function Marketplace() {
  const [filtering, setFiltering] = useState(false);
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );

  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  const totalPages = 10; // You can set this to the total number of pages available

  // Handle the previous page
  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Handle the next page
  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  // Handle page number click
  const handlePageClick = (pageNum:number) => {
    setPage(pageNum);
  };


  const [category, setCategory] = useState("all");
  const [sponsoredMode, setSponsoredMode] = useState("all");
  const handleChangeSponsorMOde = (e: any) => {
    setSponsoredMode(e.target.value); // Update selected category state when selection changes
  };
  const handleChange = (e: any) => {
    setCategory(e.target.value); // Update selected category state when selection changes
  };
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  // State variable for search query
  const [searchQuery, setSearchQuery] = useState("");

  // Handler for updating the search query
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const { loading, error, data, refetch } = useContentListQuery({
    fetchPolicy: "network-only",
    variables: {
      getContentsInput: {
        userId: user.id,
        page,
        pageSize,
        category,
        searchQuery: searchQuery,
        sponsoredMode: sponsoredMode,
        // sponsoredMode:sponsoredMode === "all" ? null : sponsoredMode,
      },
    },
  });

  // console.log(data);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    console.log("information");
    console.log("information");
    return setShow(true);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All categories");

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
    setDropdownOpen(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted with category:", selectedCategory);
  };
  const [formDataFilter, setFormDataFilter] = useState<{
    [key: string]: boolean;
  }>({});
  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setFormDataFilter((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  return (
    <div className="w-full flex bg-gray-50 justify-center flex-col items-center ">
      <div className="w-full flex max-w-screen-xl flex-col items-center ">
        <div className="w-full max-w-screen-xl">
          <Grid
            mb={3}
            templateColumns={{
              base: "1fr",
              lg: "repeat(2, 1fr)",
              ////  "2xl": "1.34fr 1.62fr 1fr",
            }}
            gap={{ base: "10px", xl: "10px" }}
          >
            <Banner />
            <BannerSide />
          </Grid>

          <Grid
            mb="20px"
            gridTemplateColumns={{
              xl: "repeat(1, 1fr)",
              // "2xl": "1fr 0.46fr"
            }}
            gap={{ base: "20px", xl: "20px" }}
            display={{ base: "block", xl: "grid" }}
          >
            {false && (
              <form className="max-w-lg w-full" onSubmit={handleSubmit}>
                <div className="flex">
                  <label
                    htmlFor="search-dropdown"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Your Email
                  </label>
                  <button
                    id="dropdown-button"
                    type="button"
                    className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                    onClick={toggleDropdown}
                  >
                    {selectedCategory}
                    <svg
                      className="w-2.5 h-2.5 ms-2.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>

                  {dropdownOpen && (
                    <div
                      id="dropdown"
                      className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                    >
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdown-button"
                      >
                        {["Mockups", "Templates", "Design", "Logos"].map(
                          (category) => (
                            <li key={category}>
                              <button
                                type="button"
                                className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={() => handleCategorySelect(category)}
                              >
                                {category}
                              </button>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}

                  <div className="relative w-full">
                    <input
                      type="search"
                      id="search-dropdown"
                      className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                      placeholder="Search Mockups, Logos, Design Templates..."
                      required
                    />
                    <button
                      type="submit"
                      className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      <span className="sr-only">Search</span>
                    </button>
                  </div>
                </div>
              </form>
            )}{" "}
            <Card
              mb={{ base: "0px", "2xl": "20px" }}
              borderRadius="8px"
              style={{
                padding: 7,
                backgroundColor: "#37005d",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Text
                  fontSize={{ base: "14px", md: "24px" }}
                  color={"#eee5e9"}
                  mb="14px"
                  maxW={{
                    base: "100%",
                    md: "64%",
                    lg: "46%",
                    xl: "70%",
                    "2xl": "50%",
                    "3xl": "42%",
                  }}
                  fontWeight="700"
                  padding="5px"
                  lineHeight={{ base: "16px", md: "21px" }}
                >
                  Search
                </Text>
                {filtering ? (
                  <Button
                    bg={"inherit"}
                    marginBottom={"5px"}
                    marginRight={"-1%"}
                    _hover={{
                      bg: "#1f0033",
                      marginRight: "0%",
                    }}
                    onClick={() => {
                      setFiltering(false);
                    }}
                  >
                    <Icon
                      as={MdArrowDownward}
                      width="20px"
                      height="20px"
                      color="white"
                    />
                  </Button>
                ) : (
                  <Button
                    bg={"inherit"}
                    marginRight={"-1%"}
                    marginBottom={"5px"}
                    _hover={{
                      bg: "#1f0033",
                      marginRight: "0%",
                    }}
                    onClick={() => {
                      setFiltering(true);
                    }}
                  >
                    <Icon
                      as={MdArrowUpward}
                      width="20px"
                      height="20px"
                      color="white"
                    />
                  </Button>
                )}
              </Box>

              {filtering && (
                <>
                  {" "}
                  {false && (
                    <Select
                      name="category"
                      borderColor={"#6b00b3"}
                      _hover={{
                        borderColor: "#8a00e6",
                      }}
                      color={"#eee5e9"}
                      width={"20%"}
                      value={category} // Set value to selected category state
                      onChange={handleChange} // Call handleChange function when selection changes
                    >
                      {[{ mode: "all", label: "All" }, ...filtersLabels].map(
                        (cat: any) => (
                          <option key={cat.mode} value={cat.mode}>
                            {cat.label}
                          </option>
                        )
                      )}
                    </Select>
                  )}
                  <div className="flex flex-row">
                    <Select
                      // marginTop={"10px"}
                      marginBottom={"10px"}
                      color={"#eee5e9"}
                      borderColor={"#6b00b3"}
                      _hover={{
                        borderColor: "#8a00e6",
                      }}
                      name="sponsoredMode"
                      width={"20%"}
                      value={sponsoredMode} // Set value to selected category state
                      onChange={handleChangeSponsorMOde} // Call handleChange function when selection changes
                    >
                      {[
                        { mode: "all", label: "All" },
                        { mode: "sponsored", label: "Sponsored" },
                        { mode: "non_sponsored", label: "Not Sponsored" },
                      ].map((cat: any) => (
                        <option key={cat.mode} value={cat.mode}>
                          {cat.label}
                        </option>
                      ))}
                    </Select>

                    <Input
                      value={searchQuery}
                      onChange={handleSearchChange}
                      name="Search"
                      borderColor={"#6b00b3"}
                      _hover={{
                        borderColor: "#8a00e6",
                      }}
                      color={"#eee5e9"}
                      placeholder={"Search here by owner or content name  "}
                      style={{
                        // borderColor:"#eef0f2",
                        // marginTop: "20px",

                        // border: "1px solid #eef0f2",
                        // position:"relative",
                        // color: "#333",
                        width: "100%",
                      }}
                    />
                  </div>
                  <div className="grid grid-flow-row-dense grid-cols-4  gap-10 grid-rows-3  rounded-2xl py-7 ">
                    {[{ mode: "all", label: "All" }, ...filtersLabels].map(
                      (filter, index) => (
                        <div key={index}>
                          <input
                            type="checkbox"
                            id={filter.mode}
                            color="white"
                            name={filter.mode} // Use 'name' instead of 'id' to match state key
                            checked={formDataFilter[filter.mode] || false} // Bind checked to state
                            onChange={handleChangeFilter} // Trigger state update on change
                          />
                          <label className="text-white" htmlFor={filter.mode}>
                            {filter.label}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                </>
              )}

              {/* <Text
          fontSize={{ base: "14px", md: "24px" }}
          color={textColor}
          mb="14px"
          maxW={{
            base: "100%",
            md: "64%",
            lg: "46%",
            xl: "70%",
            "2xl": "50%",
            "3xl": "42%",
          }}
          fontWeight="700"
          lineHeight={{ base: "16px", md: "21px" }}
        >Search</Text>

<Text
          fontSize={{ base: "14px", md: "24px" }}
          color={textColor}
          mb="14px"
          maxW={{
            base: "100%",
            md: "64%",
            lg: "46%",
            xl: "70%",
            "2xl": "50%",
            "3xl": "42%",
          }}
          fontWeight="700"
          lineHeight={{ base: "16px", md: "21px" }}
        >Next, cuurent,Previous</Text>
 */}
            </Card>
          </Grid>

          {!loading ? (
            <>
              <Grid
                mb="20px"
                gridTemplateColumns={{
                  xl: "repeat(1, 1fr)",
                  // "2xl": "1fr 0.46fr"
                }}
                gap={{ base: "20px", xl: "20px" }}
                display={{ base: "block", xl: "grid" }}
              >
                <Flex
                  flexDirection="column"
                  gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
                >
                  <Flex direction="column">
                    <SimpleGrid
                      mt={"20px"}
                      columns={{ base: 1, md: 4 }}
                      gap="20px"
                    >
                      {data?.contentList?.map((cart: any) => {
                        const existingCart = carts.find(
                          (item: ICart) => item.id === cart.id
                        );
                        return (
                          <Cart
                            key={cart.id}
                            cart={cart}
                            removeCart={removeCart}
                            selected={existingCart}
                            handleShow={handleShow}
                          />
                        );
                      })}
                    </SimpleGrid>

                    <ol className="my-6 mt-12  p-3 flex justify-center gap-1 text-md font-medium">
      {/* Previous Page Button */}
      <li>
        <button
          onClick={handlePrev}
          className="inline-flex size-14 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
        >
          <span className="sr-only">Prev Page</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </li>

      {/* Page Numbers */}
      {[...Array(totalPages).keys()].map((pageNum) => (
        <li key={pageNum}>
          <button
            onClick={() => handlePageClick(pageNum + 1)}
            className={`block size-14 rounded border text-center leading-8 ${
              page === pageNum + 1
                ? 'border-blue-600 bg-blue-600 text-white'
                : 'border-gray-100 bg-white text-gray-900'
            }`}
          >
            {pageNum + 1}
          </button>
        </li>
      ))}

      {/* Next Page Button */}
      <li>
        <button
          onClick={handleNext}
          className="inline-flex size-14 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
        >
          <span className="sr-only">Next Page</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </li>
    </ol>
                  </Flex>
                </Flex>
              </Grid>
            </>
          ) : (
            <>
              <div className="flex justify-center mt-20 h-screen">
                <div
                  className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import ButtonCustom from "components_app/button/Button";
import { FiFilm, FiHome, FiUsers } from "react-icons/fi";
import { greeting } from "./portfolio";
import { Fade } from "react-awesome-reveal";
import "./Greeting.css";
// import "./Footer.css";
import "./Header.css";
// import "./Contents.css";
// import "./Section.css";
// import "./Sponsors.css";
// import "./Gallery.css";
import testimonials from "../data/testimonials";

// import { navigateThePage } from "redux.store/navigation/actionCreators";
import {
  logoutUser,
  loginUser,
  changeLanguage,
  toggleDarkModeState,
} from "redux.store/user/actionCreators";
import { useHistory } from "react-router-dom";
import {
  Flex,
  Text,
  Button,
  Link,
  Box,
  SimpleGrid,
  useClipboard,
  Card,
} from "@chakra-ui/react";
import { FaPrayingHands } from "react-icons/fa";
import { Stack, Icon, Wrap, Tag } from "@chakra-ui/react";
import {
  Highlights,
  HighlightsItem,
  HighlightsTestimonialItem,
} from "components_app/highlights";
import { Testimonial, Testimonials } from "components_app/testimonials";
import { MdArrowBack, MdArrowForward, MdNewspaper } from "react-icons/md";
import { useContentListQuery } from "generated/graphql";
import { Cart } from "components_app/Cart_Components_Landing/Cart";
import VideoPlayer from "./components/videoPlayer/videoPlayer";
import image1 from "assets/images/haki.jpg";
import image2 from "assets/images/ujasiliamali.jpg";
import image3 from "assets/images/home2.png";
import image4 from "assets/img/sponsorsFriends/Ubongo-Kids-Logo.png";

const Home: React.FC = (props: any) => {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 628);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  // NAVIGATION FUNCTIONS
  // const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  // const saveCurrentRoute = React.useCallback(
  //   (navData: INavigate) => dispatch(navigateThePage(navData)),
  //   [dispatch]
  // );
  const navigateThroughLinks = (navData: INavigate) => {
    // Define your navData object here
    // Call the saveCurrentRoute function with the navData
    // saveCurrentRoute(navData);
    // navigate(`/${navData.currentPage}`);
  };
  // NAVIGATION FUNCTIONS

  //Logout
  const logoutService = React.useCallback(
    () => dispatch(logoutUser()),
    [dispatch]
  );
  // const logout = (user: IUser) => {
  //   logoutService(user);
  //   navigateThroughLinks({
  //     id: 2,
  //     currentPage: "mainFeed",
  //     component: "MainFeed",
  //   });
  // };

  //Logout

  //Login
  const loginService = React.useCallback(
    (user: IUser) => dispatch(loginUser(user)),
    [dispatch]
  );
  // const login = (user: IUser) => {
  //   loginService(user);
  //   navigateThroughLinks({
  //     id: 2,
  //     currentPage: "mainFeed",
  //     component: "MainFeed",
  //   });
  // };
  //Login

  //changeLanguageService
  const changeLanguageService = React.useCallback(
    (user: IUser) => dispatch(changeLanguage(user)),
    [dispatch]
  );

  // const changeLanguageNow = (languageType: string) => {
  //   changeLanguageService({ ...user, language: languageType });
  // };
  //changeLanguageService

  // const { i18n } = useTranslation();

  // const handleLanguageChange = (language: string) => {
  //   i18n.changeLanguage(language);
  //   changeLanguageNow(language);
  // };

  // const { t } = useTranslation();

  // const navigateToMainApp = () => {
  //   if (user.loginStatus) {
  //     navigateThroughLinks({
  //       id: 2,
  //       currentPage: "mainFeed",
  //       component: "MainFeed",
  //     });
  //   } else {
  //     navigateThroughLinks({
  //       id: 2,
  //       currentPage: "login",
  //       component: "Loginb  ban       ",
  //     });
  //   }
  //   // alert("Button clicked!");
  // };

  // dark mode
  //changeLanguageService
  const changeDarkMode = React.useCallback(
    (user: IUser) => dispatch(toggleDarkModeState(user)),
    [dispatch]
  );

  const { loading, error, data, refetch } = useContentListQuery({
    fetchPolicy: "network-only",
    variables: {
      getContentsInput: {
        userId: user.id,
        page: 1,
        pageSize: 8,
        category: "all",
        // searchQuery: "searchQuery",
        // sponsoredMode: sponsoredMode,
        // sponsoredMode:sponsoredMode === "all" ? null : sponsoredMode,
      },
    },
  });

  // const toggleDarkMode = () => {
  //   console.log("in profile");
  //   // console.log("in profile");
  //   // console.log("in profile");
  //   // console.log("in profile");
  //   // console.log("in profile");
  //   // console.log(localStorage.getItem("isDarkMode"));
  //   // console.log(localStorage.getItem("isDarkMode"));
  //   // console.log(localStorage.getItem("isDarkMode"));
  //   changeDarkMode({ ...user, darkMode: user.darkMode });
  // };

  // const videoContent = [
  //   { id: 1, name: "Video 1" },
  //   { id: 2, name: "Video 2" },
  //   { id: 3, name: "Video 3" },
  //   { id: 4, name: "Video 4" },
  //   { id: 5, name: "Video 5" },
  //   { id: 6, name: "Video 6" },
  //   { id: 7, name: "Video 7" },
  //   { id: 8, name: "Video 8" },
  //   { id: 9, name: "Video 9" },
  //   { id: 10, name: "Video 10" },
  // ];

  //changeLanguageService
  // const theme = props.theme;
  const blueTheme = {
    body: "#EDF9FE",
    text: "#001C55",
    expTxtColor: "#000a12",
    highlight: "#A6E1FA",
    dark: "#00072D",
    secondaryText: "#7F8DAA",
    imageHighlight: "#0E6BA8",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#0A2472",
    headerColor: "#0E6BA877",
    splashBg: "#001C55",
  };
  const theme = blueTheme;

  const slideRef = useRef(null);

  const handleNext = () => {
    const items = document.querySelectorAll(".itemGallery");
    slideRef.current.appendChild(items[0]);
  };

  const handlePrev = () => {
    const items = document.querySelectorAll(".itemGallery");
    slideRef.current.prepend(items[items.length - 1]);
  };

  const [scrollIndex, setScrollIndex] = useState(0);

  const containerRef = useRef(null);

  const handleNextItem = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 300, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const handleBackItem = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -300, // Scroll to the left (negative value)
        behavior: "smooth",
      });
    }
  };

  const dataLand = [
    {
      id: 1,
      title: "New Sponsor",
      description: "Wizara ya Afya is a new banzdajamii sponsor",
    },
    {
      id: 2,
      title: "New Content",
      description: "Elimu ya afya kwa wabeba mizigo",
    },
    { id: 3, title: "New Banda", description: "Banda Q0001 added" },
    {
      id: 4,
      title: "Amazing Buza",
      description: "Events in buza were amazing ",
    },
  ];

  return (
    <div className="w-full flex justify-center flex-col items-center ">
      <div className="w-full flex max-w-screen-xl flex-col items-center ">
        {/* header */}
        <div className="container  ">
          <nav className="flex flex-row  justify-between">
            <div
              className="logo"
              style={{
                display: "flex",
                marginLeft: "-1%",
                alignSelf: "start",
              }}
            >
              <Text
                style={{ color: "gray", fontSize: 22, fontWeight: "bolder" }}
                className="md:ml-10"
              >
                <span
                  style={{
                    color: theme.text,
                    fontSize: 18,
                    fontWeight: "bolder",
                  }}
                >
                  Banda
                </span>
                Jamii
              </Text>
            </div>

            {/* <ul>
            <div className="btn">
              <i className="fas fa-times close-btn"></i>
            </div>
            <li><a href="#">Home</a></li>
            <li><a href="#">About</a></li>
            <li><a href="#">Services</a></li>
            <li><a href="#">Contact</a></li>
          </ul> */}

            <Flex flexDirection={"row"}>
              <div
                className="btn"
                style={{
                  marginRight: 30,
                }}
              >
                {/* <Link
                href="#/site/register_banda"
                style={{ color: theme.text, fontSize: 16, fontWeight: "bold" }}
              >
                <span>Register Banda</span>
              </Link> */}
              </div>
              <div className="btn   md:mr-5">
                <Link
                  href="#/auth"
                  style={{
                    color: theme.text,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  <span>Sign In</span>
                </Link>
              </div>
            </Flex>
            {/* <div className="btn">
              <Link
                href="#/auth"
                style={{ color: theme.text, fontSize: 16, fontWeight: "bold" }}
              >
                <span>Register</span>
              </Link>
            </div> */}
          </nav>
        </div>

        {/* Hero */}
        <div
          style={{
            paddingTop: "5%",
            backgroundColor: "white",
            marginBottom: 20,
          }}
        >
          <div className="greet-main" id="greeting">
            <div
              className="greeting-main"
              style={{
                paddingTop: 10,
                marginTop: 0,
              }}
            >
              <div
                className="greeting-text-div"
                style={{
                  paddingTop: 10,
                  marginLeft: 30,
                }}
              >
                <div
                  style={{
                    marginTop: 50,
                    // backgroundColor: "red",
                  }}
                >
                  <Fade
                    // bottom
                    duration={600}
                    direction={"up"}
                    cascade={true}
                    fraction={0}
                    style={{
                      marginBottom: 20,
                      //marginTop:-70,
                    }}
                  >
                    {" "}
                    <Text
                      style={{
                        color: theme.text,
                        fontWeight: "bolder",
                      }}
                      className="greeting-text"
                    >
                      Engage with the community effortlessly
                    </Text>
                    {greeting.nickname && (
                      <Text
                        //className="greeting-nickname"
                        style={{
                          color: theme.text,
                          marginTop: 20,
                          marginBottom: 20,
                          marginLeft: 5,
                          fontSize: 18,
                          fontWeight: 500,
                        }}
                      >
                        We take your message to the local streets
                      </Text>
                    )}
                    <Text
                      // className="greeting-text-p subTitle"
                      style={{
                        color: theme.secondaryText,
                        fontSize: 16,
                      }}
                    >If you have educational content to spread to the community we are here to help you target your audience.</Text>{" "}
                  </Fade>

                  <div
                    className="portfolio-repo-btn-div"
                    style={{
                      display: "flex",
                      marginTop: 30,
                      // alignContent:"center",
                      // justifyContent:"center",
                    }}
                  >
                    <ButtonCustom
                      text="Start now"
                      onClick={() => {
                        history.push("/site/explore");
                      }}
                      theme={theme}
                      className="portfolio-repo-btn rounded-2xl"
                      styles={{
                        marginTop: "20%",
                        //  marginLeft:"-5%",
                        padding: 20,
                        backgroundColor: "#44034d",
                      }}
                    />
                  </div>
                </div>
              </div>
              <VideoPlayer />
              {false && (
                <div className=" h-[400px] p-5 ">
                  <div className="w-full h-full rounded-3xl bg-gray-500">
                    <iframe
                      className="w-full rounded-3xl h-full max-w-full"
                      src="https://www.youtube.com/embed/p4qvFDadxfw?si=vhhvKkhEEfvoQX-M"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* banner */}
        <Flex
          // className="stakeholders"
          width={"100%"}
          height={"auto"}
          marginLeft={"0px"}
          marginTop={"50px"}
          // style={{
          //   backgroundColor:"blue",
          // }}
        >
          <Flex
            flexDirection={"column"}
            className="tangazoGradient stakeholdersItem shadow-md"
            // width={"100%"}
            // alignSelf={"center"}
            borderRadius={"10px"}
            paddingRight={"1%"}
          >
            <Flex
              // flexDirection={"column"}
              height={"auto"}
              width={"100%"}
              alignSelf={"center"}
              borderRadius={"10px"}
              className="  tangazoGradientBox"
            >
              {/* Side ways */}
              <Flex
                // width={"30%"}
                justifyContent={"center"}
                alignContent={"center"}
                flexDirection={"column"}
                paddingLeft={"5%"}
                className=" widthAll "
              >
                <Fade
                  // bottom
                  duration={600}
                  direction={"left"}
                  cascade={true}
                  fraction={0}
                >
                  <Text
                    // className="greeting-text-section-head"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      marginTop: "-10%",
                      // textAlign: "center",
                    }}
                    fontSize={"small"}
                  >
                    <Icon as={MdNewspaper} color="inherit" /> get new info
                  </Text>
                </Fade>
                <Fade
                  // bottom
                  duration={600}
                  direction={"left"}
                  cascade={true}
                  fraction={0}
                >
                  <Text
                    className="greeting-text-section-head"
                    style={{
                      color: "#fff",
                      // textAlign: "center",
                      fontSize: 33,
                      lineHeight: 1.1,
                      fontWeight: "bold",
                    }}
                  >
                    Communities needs you
                  </Text>
                </Fade>
              </Flex>

              {/* lists */}
              <Flex
                alignContent={"center"}
                flexDirection={"row"}
                paddingLeft={"2%"}
                overflowX={"auto"}
                ref={containerRef}
                className=" widthList scroll-container "
              >
                {dataLand.map((item: any) => {
                  return (
                    <Box
                      key={item.id}
                      style={{
                        display: "flex",
                        height: "300px",
                        width: "350px",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      {" "}
                      <Card
                        alignItems="center"
                        alignSelf={"center"}
                        flexDirection="column"
                        borderRadius={8}
                        w="98%"
                        // maxW="max-content"
                        bg={"#2c0f30"}
                        p="20px 15px"
                        h="80%"
                      >
                        <Button
                          onClick={() => {}}
                          bg={"#44034d"}
                          // width={"20px"}
                          // height={"20px"}
                        >
                          <Text
                            style={{
                              color: "white",
                              // textAlign: "center",
                              fontWeight: "bold",
                            }}
                            fontSize={"12px"}
                          >
                            {item.title}
                          </Text>
                        </Button>

                        <Text
                          style={{
                            // color: "#333",
                            color: "white",

                            marginTop: "40px",
                            // textAlign: "center",
                            fontWeight: "bold",
                          }}
                          fontSize={"16px"}
                        >
                          {item.description}
                        </Text>
                        <Button
                          onClick={() => {}}
                          bg={"inherit"}
                          width={"20px"}
                          bottom={"5px"}
                          right={"0px"}
                          height={"20px"}
                          position={"absolute"}
                        >
                          <Icon as={MdArrowForward} color="white" />
                        </Button>
                      </Card>
                    </Box>
                  );
                })}
              </Flex>
            </Flex>

            <Flex
              flexDirection={"row"}
              // position={"absolute"}
              // bg={"orange"}
              height={"10%"}
              justifyContent={"end"}
              paddingBottom={"2%"}
              // marginTop={"20%"}
              // right={"110px"}
              // bottom={"1%"}
            >
              <Button
                onClick={handleBackItem}
                bg={"inherit"}
                width={"20px"}
                height={"20px"}
              >
                <Icon as={MdArrowBack} color="white" />
              </Button>
              <Button
                onClick={handleNextItem}
                bg={"inherit"}
                width={"20px"}
                height={"20px"}
              >
                <Icon as={MdArrowForward} color="white" />
              </Button>
            </Flex>
          </Flex>

          {false && (
            <Flex
              className=" stakeholdersItemRight "
              bg={"black"}
              style={{
                height: "auto",
                backgroundColor: "black",
                borderRadius: 15,
              }}
              paddingRight={"5%"}
              paddingLeft={"5%"}
            >
              <Flex
                flexDirection={"column"}
                bg={"black"}
                // className="tangazoGradient "
                height={"450px"}
                width={"100%"}
                borderRadius={"10px"}
              >
                <Text
                  // className="greeting-text-section-head"
                  style={{
                    color: "#fff",
                    marginTop: "20px",
                    // fontWeight: "bold",
                    fontSize: 14,
                    //  padding:10,
                    textAlign: "end",
                    lineHeight: 1.1,
                  }}
                >
                  add
                </Text>
                <Fade
                  // bottom
                  duration={600}
                  direction={"right"}
                  cascade={true}
                  fraction={0}
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Text
                    // className="greeting-text-section-head"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: 38,
                      //  padding:10,
                      lineHeight: 1.1,
                    }}
                  >
                    Kamata Fursa twenzetu
                  </Text>
                </Fade>

                <Fade
                  // bottom
                  duration={600}
                  direction={"right"}
                  cascade={true}
                  fraction={0}
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Text
                    // className="greeting-text-section-head"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: 24,
                      //  padding:10,
                      lineHeight: 1.1,
                    }}
                  >
                    Hakimiliki yako hailiki
                  </Text>
                </Fade>
                <Fade
                  // bottom
                  duration={600}
                  direction={"left"}
                  cascade={true}
                  fraction={0}
                  style={{
                    marginTop: 20,
                  }}
                >
                  {/* <Text
                            // className="greeting-text-section-head"
                            style={{
                              color: "#fff",
                              // fontWeight: "bold",
                             fontSize:12,
                            //  padding:10,
                             lineHeight: 1.1,
                            }}
                          >add</Text> */}
                </Fade>
              </Flex>
            </Flex>
          )}
        </Flex>

        {/* contentss to show */}

        <Flex
          // className="stakeholders"
          width={"100%"}
          height={"auto"}
          marginTop={"50px"}
        >
          <Flex
            flexDirection={"column"}
            // className=" stakeholdersItem "
            width={"100%"}
            borderRadius={"10px"}
            paddingRight={"1%"}
            paddingLeft={"1%"}
          >
            <Flex
              height={"auto"}
              width={"100%"}
              // borderRadius={"10px"}
              paddingBottom={"20px"}
              className="p-4  bg-gray-50 "
            >
              <SimpleGrid
                className=" w-full "
                mt={"20px"}
                columns={{ base: 1, md: 4 }}
                gap="20px"
              >
                {data?.contentList?.map((cart: any) => {
                  //if has image
                  return (
                    <div className="w-full">
                      <Cart
                        key={cart.id}
                        cart={cart}
                        removeCart={(data: any) => {}}
                        selected={false}
                        handleShow={() => {}}
                      />
                    </div>
                  );
                })}
              </SimpleGrid>
            </Flex>
          </Flex>

          {/* Side ways */}
          {false && (
            <Flex
              // width={"30%"}
              // justifyContent={"center"}
              // alignContent={"center"}
              flexDirection={"column"}
              paddingLeft={"5%"}
              className=" widthAll "
            >
              <Fade
                // bottom
                duration={600}
                direction={"left"}
                cascade={true}
                fraction={0}
              >
                <Text
                  className="greeting-text-section-head"
                  style={{
                    color: "#fff",
                    // textAlign: "center",
                    fontSize: 33,
                    lineHeight: 1.1,
                    fontWeight: "bold",
                  }}
                >
                  Sample Contents
                </Text>
              </Fade>
            </Flex>
          )}

          {/* lists */}
          {false && (
            <Flex
              alignContent={"center"}
              flexDirection={"row"}
              paddingLeft={"2%"}
              overflowX={"auto"}
              ref={containerRef}
              className=" widthList scroll-container "
            >
              {dataLand.map((item: any) => {
                return (
                  <Box
                    key={item.id}
                    style={{
                      display: "flex",
                      height: "300px",
                      width: "350px",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    {" "}
                    <Card
                      alignItems="center"
                      alignSelf={"center"}
                      flexDirection="column"
                      borderRadius={8}
                      w="98%"
                      // maxW="max-content"
                      bg={"#822faf"}
                      p="20px 15px"
                      h="80%"
                    >
                      <Button
                        onClick={() => {}}
                        bg={"#db00b6"}
                        // width={"20px"}
                        // height={"20px"}
                      >
                        {/* <Icon as={MdArrowBack} color="inherit" /> */}
                        <Text
                          style={{
                            color: "white",
                            // textAlign: "center",
                            fontWeight: "bold",
                          }}
                          fontSize={"12px"}
                        >
                          {item.title}
                        </Text>
                      </Button>

                      <Text
                        style={{
                          // color: "#333",
                          color: "white",

                          marginTop: "40px",
                          // textAlign: "center",
                          fontWeight: "bold",
                        }}
                        fontSize={"16px"}
                      >
                        {item.description}
                      </Text>
                      <Button
                        onClick={() => {}}
                        bg={"inherit"}
                        width={"20px"}
                        bottom={"5px"}
                        right={"0px"}
                        height={"20px"}
                        position={"absolute"}
                      >
                        <Icon as={MdArrowForward} color="white" />
                      </Button>
                    </Card>
                  </Box>
                );
              })}
            </Flex>
          )}

          {false && (
            <Flex
              className=" stakeholdersItemRight shadow-md"
              bg={"black"}
              style={{
                height: "auto",
                backgroundColor: "black",
                borderRadius: 15,
              }}
              paddingRight={"5%"}
              paddingLeft={"5%"}
            >
              <Flex
                flexDirection={"column"}
                bg={"black"}
                // className="tangazoGradient "
                height={"450px"}
                width={"100%"}
                borderRadius={"10px"}
              >
                <Text
                  // className="greeting-text-section-head"
                  style={{
                    color: "#fff",
                    marginTop: "20px",
                    // fontWeight: "bold",
                    fontSize: 14,
                    //  padding:10,
                    textAlign: "end",
                    lineHeight: 1.1,
                  }}
                >
                  add
                </Text>
                <Fade
                  // bottom
                  duration={600}
                  direction={"right"}
                  cascade={true}
                  fraction={0}
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Text
                    // className="greeting-text-section-head"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: 38,
                      //  padding:10,
                      lineHeight: 1.1,
                    }}
                  >
                    Kamata Fursa twenzetu
                  </Text>
                </Fade>

                <Fade
                  // bottom
                  duration={600}
                  direction={"right"}
                  cascade={true}
                  fraction={0}
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Text
                    // className="greeting-text-section-head"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: 24,
                      //  padding:10,
                      lineHeight: 1.1,
                    }}
                  >
                    Hakimiliki yako hailiki
                  </Text>
                </Fade>
                <Fade
                  // bottom
                  duration={600}
                  direction={"left"}
                  cascade={true}
                  fraction={0}
                  style={{
                    marginTop: 20,
                  }}
                >
                  {/* <Text
                            // className="greeting-text-section-head"
                            style={{
                              color: "#fff",
                              // fontWeight: "bold",
                             fontSize:12,
                            //  padding:10,
                             lineHeight: 1.1,
                            }}
                          >add</Text> */}
                </Fade>
              </Flex>
            </Flex>
          )}
        </Flex>

        {/* banmdajamiii news */}

        {false && (
          <Fade
            // bottom
            duration={600}
            direction={"down"}
            cascade={true}
            fraction={0}
            style={{
              marginBottom: 0,
              marginTop: 100,
              alignSelf: "center",
              textAlign: "center",
            }}
            // className="shorter"
          >
            <Text
              // className="greeting-text   greeting-text-section-head-community"

              style={{
                color: "gray",
                fontWeight: "bold",
                fontSize: 33,
                textDecoration: "none",
              }}
            >
              Inspiring activities
            </Text>
          </Fade>
        )}

        

        {/* contents view */}
        {false && (
          <div
            style={{
              // backgroundColor: "#4a006f",
              // backgroundColor:"#f3cfce",
              backgroundColor: "#fffffa",
              paddingBottom: "10%",
              marginBottom: 6,
              marginTop: 100,
            }}
          >
            <Fade
              // bottom
              duration={600}
              direction={"down"}
              cascade={true}
              fraction={0}
              style={{
                marginTop: 5,
                alignSelf: "start",
                textAlign: "center",
              }}
            >
              <Text
                // className="greeting-text-section-head-community"
                style={{
                  color: "#333",
                  fontWeight: "bold",
                  fontSize: 33,
                  textDecoration: "none",
                }}
              >
                Content
              </Text>
            </Fade>

            {/* <Fade
          // bottom
          duration={600}
          direction={"down"}
          cascade={true}
          fraction={0}
          style={{
            marginBottom: -100,
            marginTop: 10,
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <Text
            // className="greeting-text-section-head-community"
            style={{
              color: "#333",
              fontWeight: "bold",
              fontSize: 18,
              textDecoration: "none",
            }}

          >
            Explore Content...visit and see what you can help spread to the
            community
          </Text>
        </Fade> */}

            <Flex
              className="stakeholders"
              marginTop={"100px"}
              marginBottom={"-30px"}
            >
              {testimonials.items.map((item: any) => {
                return (
                  <div key={item.name}>
                    <HighlightsTestimonialItem
                      marginTop={"10px"}
                      marginLeft={"20px"}
                      marginRight={"20px"}
                      name={item.name}
                      description={item.description}
                      avatar="/static/images/avatar.jpg"
                      gradient={["pink.900", "purple.900"]}
                      style={{
                        borderRadius: 15,
                      }}
                    >
                      <Button
                        type="submit"
                        onClick={() => {
                          // handleSubmit();
                          history.push("/site/explore");
                        }}
                        colorScheme="violet.800"
                        marginTop="4"
                        style={{
                          width: "80%",
                          top: "20px",
                          paddingTop: 5,
                          paddingBottom: 5,
                          bottom: 20,
                          position: "absolute",
                          backgroundColor: "violet.800",
                          // alignSelf: "center",
                        }}
                      >
                        View{" "}
                      </Button>
                    </HighlightsTestimonialItem>
                  </div>
                );
              })}
            </Flex>
          </div>
        )}

        {/* stake holders */}
        <>
          <Fade
            // bottom
            duration={600}
            direction={"down"}
            cascade={true}
            fraction={0}
            style={{
              marginTop: 150,
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            <Text
              // className="greeting-text-section-head-community"
              style={{
                color: "#333",
                fontWeight: "bold",
                fontSize: 33,
                textDecoration: "none",
              }}
            >
              Our Stakeholders
            </Text>
          </Fade>

          <Flex
            flexDirection={"column"}
            marginTop={"120px"}
            marginBottom={"300px"}
          >
            {[
              {
                title: "Content",
                orientation: "left",
                icon: FiFilm,
                description:
                  "Easily connect with organizations to showcase your content and broaden audience reach effortlessly.",
                variant: "inline",
                image: image1,
                color: "#e1ded5",
                link: "/site/explore",
              },
              {
                title: "Community",
                orientation: "right",
                icon: FiUsers,
                color: "#f1e4f3",
                description:
                  "Active involvement in our dynamic community enriches experiences and fosters shared knowledge growth.",
                variant: "inline",
                image: image1,

                link: "/site/explore",
              },
              {
                title: "Bandajamii",
                orientation: "left",
                color: "#ffdce0",

                icon: FiHome,
                image: image1,

                description:
                  "Join our vibrant community hub to unlock creativity and foster connections that matter.",
                variant: "inline",
                link: "/site/banda_explore",
              },
              {
                title: "Sponsors",
                icon: FaPrayingHands,
                color: "#fffbe9",

                orientation: "right",
                image: image1,

                description:
                  "Engage with creators and venues to support collaborations and drive impactful initiatives.",
                variant: "inline",
                link: "/site/sponsor_explore",
              },
            ].map((item: any, index) => {
              return (
                <div key={item.title + item.link} className="my-4">
                  {item.orientation === "left" ? (
                    <>
                      <section className="overflow-hidden bg-gray-50 sm:grid sm:grid-cols-2 sm:items-center">
                        <div className="p-8 md:p-12 lg:px-16 lg:py-24">
                          <div className="mx-auto max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
                            <Fade
                              // bottom
                              duration={600}
                              direction={"up"}
                              cascade={true}
                              fraction={0}
                              style={{
                                alignSelf: "center",
                                textAlign: "center",
                              }}
                            >
                              <h2 className="text-2xl font-bold text-gray-900 md:text-3xl">
                                {item.title}
                              </h2>
                            </Fade>

                            <Fade
                              // bottom
                              duration={600}
                              direction={"down"}
                              cascade={true}
                              fraction={0}
                              style={{
                                alignSelf: "center",
                                textAlign: "center",
                              }}
                            >
                              <p className="text-md hidden text-gray-500 md:mt-4 md:block">
                                {item.description}
                              </p>
                            </Fade>
                            <div className="mt-4 md:mt-8">
                              <p
                                onClick={() => {
                                  history.push(item.link);
                                }}
                                className="inline-block rounded bg-emerald-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-yellow-400"
                              >
                                Get Started Today
                              </p>
                            </div>
                          </div>
                        </div>

                        <Fade
                          // bottom
                          duration={600}
                          direction={"right"}
                          cascade={true}
                          fraction={0}
                        >
                          <img
                            alt=""
                            src={item.image}
                            className="p-4 h-full w-full object-cover sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-se-[30px] md:h-[calc(100%_-_4rem)] md:rounded-se-[60px]"                          />
                        </Fade>
                      </section>
                    </>
                  ) : (
                    <>
                      {isMobile ? (
                        <>
                          <section className="overflow-hidden bg-gray-50 sm:grid sm:grid-cols-2 sm:items-center">
                            <div className="p-8 md:p-12 lg:px-16 lg:py-24">
                              <div className="mx-auto max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
                                <Fade
                                  // bottom
                                  duration={600}
                                  direction={"down"}
                                  cascade={true}
                                  fraction={0}
                                  style={{
                                    alignSelf: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2 className="text-2xl font-bold text-gray-900 md:text-3xl">
                                    {item.title}
                                  </h2>
                                </Fade>

                                <Fade
                                  // bottom
                                  duration={600}
                                  direction={"down"}
                                  cascade={true}
                                  fraction={0}
                                  style={{
                                    alignSelf: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <p className="text-md hidden text-gray-500 md:mt-4 md:block">
                                    {item.description}
                                  </p>
                                </Fade>

                                <div className="mt-4 md:mt-8">
                                  <a
                                    href="#"
                                    className="inline-block rounded bg-emerald-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-yellow-400"
                                  >
                                    Get Started Today
                                  </a>
                                </div>
                              </div>
                            </div>

                            <img
                              alt=""
                              src={item.image}
                              className="h-full w-full object-cover sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-ss-[30px] md:h-[calc(100%_-_4rem)] md:rounded-ss-[60px]"
                            />
                          </section>
                        </>
                      ) : (
                        <>
                          <section className="overflow-hidden bg-gray-50 sm:grid sm:grid-cols-2 sm:items-center">
                            <Fade
                              // bottom
                              duration={600}
                              direction={"left"}
                              cascade={true}
                              fraction={0}
                            >
                              <img
                                alt=""
                                src={item.image}
                                className="p-4 h-full w-full object-cover sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-ss-[30px] md:h-[calc(100%_-_4rem)] md:rounded-ss-[60px]"
                              />
                            </Fade>
                            <div className="p-8 md:p-12 lg:px-16 lg:py-24">
                              <div className="mx-auto max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
                                <Fade
                                  // bottom
                                  duration={600}
                                  direction={"down"}
                                  cascade={true}
                                  fraction={0}
                                  style={{
                                    alignSelf: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2 className="text-2xl font-bold text-gray-900 md:text-3xl">
                                    {item.title}
                                  </h2>
                                </Fade>

                                <Fade
                                  // bottom
                                  duration={600}
                                  direction={"up"}
                                  cascade={true}
                                  fraction={0}
                                  style={{
                                    alignSelf: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <p className="text-md hidden text-gray-500 md:mt-4 md:block">
                                    {item.description}
                                  </p>
                                </Fade>

                                <div className="mt-4 md:mt-8">
                                  <a
                                    href="#"
                                    className="inline-block rounded bg-emerald-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-yellow-400"
                                  >
                                    Visit
                                  </a>
                                </div>
                              </div>
                            </div>
                          </section>
                        </>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </Flex>
        </>

        {/* donate */}
        {false && (
          <div
            style={{
              marginTop: -250,
              backgroundColor: "#fffffa",
              // backgroundColor: "#190028",
            }}
          >
            <HighlightsSection />
          </div>
        )}
        {/* Sponsors */}

        {/* <div
        style={{
          width: "90%",
          // height:"100%",
          marginLeft: "5%",
          // flexDirection:"column",
          height: "20vh",
          // display: "flex",
          justifyContent: "center",
          alignContent: "center",
          backgroundColor: "white",
        }}
      >
        <Fade
          // bottom
          duration={600}
          direction={"down"}
          cascade={true}
          fraction={0}
          style={{
            marginBottom: 20,
            marginTop: 300,
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <Text
            // className="greeting-text-section-head-community"
            style={{
              color: "gray",
              fontWeight: "bold",
              fontSize: 22,
              textDecoration: "none",
            }}
          >
            Sponsors and Friends
          </Text>
        </Fade>

        <ul
          className="sponsor-grid"
          style={{
            display: "flex",
            // backgroundColor:"orange",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
          }}
        >
          <li className="sponsor">
            <img
              src={ubongo}
              alt="ubongo"
              // style={{
              //   width:100,
              // }}
            />
          </li>

          <li className="sponsor">
            <img src={ubongo} alt="ubongo kids" />
          </li>

          <li className="sponsor">
            <img src={ubongo} alt="Ubongo Kids" />
          </li>

          <li className="sponsor">
            <img src={ubongo} alt="Ubongo Kids" />
          </li>
        </ul>
      </div> */}

        {false && (
          <footer
            className="footer-distributed"
            style={{
              marginTop: 0,
              // backgroundColor:"#ebe0f5",
            }}
          >
            <div className="footer-left">
              <Text
                style={{ color: "gray", fontSize: 22, fontWeight: "bolder" }}
              >
                <span
                  style={{ color: "white", fontSize: 18, fontWeight: "bolder" }}
                >
                  Banda
                </span>
                Jamii
              </Text>

              {/* <p className="footer-links">
            <a href="#" className="link-1">
              Faq
            </a>


            <a href="#">Help</a>


            <a href="#">Contact</a>
          </p> */}

              <p
                className="footer-company-name"
                style={{
                  color: "white",
                }}
              >
                Bandajamii © 2024
              </p>
            </div>

            <div className="footer-center">
              {/* <div>
            <i className="fa fa-map-marker"></i>
            <p>
              <span>0000 Street. Dar abc</span> Address address, Dar es salaam
            </p>
          </div> */}

              <div>
                <i className="fa fa-phone"></i>
                <p>+255 700 000 000</p>
              </div>

              <div>
                <i className="fa fa-envelope"></i>
                <p>
                  <a href="mailto:support@bandajamii.com">
                    support@bandajamii.com
                  </a>
                </p>
              </div>
            </div>

            {/* <div className="footer-right">
          <p className="footer-company-about">
            <span>About us</span>We're your platform for reserving space, funding content to spread on our streets, and sharing your own content. Join us to bring your ideas to life and connect with your community</p>

          <div className="footer-icons">
            <a href="#" style={{ backgroundColor: "transparent" }}>
              <FaFacebook color="white" />
            </a>
            <a href="#" style={{ backgroundColor: "transparent" }}>
              <FaTwitter color="white" />
            </a>
          </div>
        </div> */}
          </footer>
        )}
      </div>{" "}
    </div>
  );
};

const HighlightsSection = () => {
  const { value, onCopy, hasCopied } = useClipboard("yarn add @saas-ui/react");

  return (
    <Highlights
      style={{
        border: "none",
        paddingTop: 100,
        paddingBottom: 40,
      }}
    >
      <HighlightsTestimonialItem
        name="Bucket Fund"
        description="Donate"
        avatar="/static/images/avatar.jpg"
        gradient={["pink.600", "purple.500"]}
      >
        <div>
          {/* <FormLabel
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={"black"}
            display="flex"
          >Amount</FormLabel>
          <Input
            type="text"
            name="title"
            variant="auth"
            value={formData.title}
            onChange={handleChange}
          /> */}

          <Button
            type="submit"
            onClick={() => {
              // handleSubmit();
            }}
            colorScheme="violet"
            marginTop="4"
            style={{
              width: "80%",
              backgroundColor: "violet",
              alignSelf: "center",
            }}
          >
            Donate Here
          </Button>
        </div>
      </HighlightsTestimonialItem>
      <HighlightsItem
        colSpan={[1, null, 2]}
        title="Change lives "
        padding={"5px"}
        style={{
          paddingTop: 10,
          paddingLeft: 20,
          backgroundColor: "#f2f7ff",
        }}
      >
        <Text color="muted" fontSize="lg">
          We collect funds and used to spread education and awareness on several
          agendas{" "}
        </Text>
        <Fade
          // bottom
          duration={600}
          direction={"right"}
          cascade={true}
          fraction={0}
          style={{
            marginBottom: 20,
            // marginTop: 70,
            // alignSelf: "center",
            // textAlign: "center",
          }}
        >
          <Wrap mt="8">
            {[
              "education",
              "women health",
              "children health",
              "environment",
              "legal rights",
              "sports",
              "entrepreneurship",
              // "advertisemnts",
              "and so on",
              // "documentation",
              // "onboarding",
              // "storybooks",
              // "theming",
              // "upselling",
              // "unit testing",
              // "feature flags",
              // "responsiveness",
            ].map((value) => (
              <Tag
                key={value}
                variant="subtle"
                colorScheme="purple"
                rounded="full"
                px="3"
              >
                {value}
              </Tag>
            ))}
          </Wrap>
        </Fade>
      </HighlightsItem>
    </Highlights>
  );
};

const TestimonialsSection = () => {
  const columns = React.useMemo(() => {
    return testimonials.items.reduce<Array<typeof testimonials.items>>(
      (columns: any, t: any, i: any) => {
        columns[i % 3].push(t);

        return columns;
      },
      [[], [], []]
    );
  }, []);

  return (
    <Testimonials
      title={""}
      columns={[1, 2, 3]}
      innerWidth="container.xl"
      marginTop={"140px"}
      style={{
        paddingBottom: 100,
        paddingTop: 100,
      }}
    >
      <>
        {columns.map((column: any, i: any) => (
          <Stack key={i} spacing="8">
            {column.map((t: any, i: any) => (
              <Testimonial key={i} {...t} />
            ))}
          </Stack>
        ))}
      </>
    </Testimonials>
  );
};

export default Home;

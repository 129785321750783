import * as React from "react";
import "./ContentOwnerItem.css";
import imageHolder from "assets/images/imageHolder.png";
import { useHistory } from "react-router-dom";
import { formatDateTime } from "views/site/helpers";

type Props = {
  c_owner: any;
  removeCart: (c_owner: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
};

export const ContentOwnerItem: React.FC<Props> = ({ c_owner }) => {
  const history = useHistory();
  return (
    <div className="bg-white  p-3 border border-gray-200 rounded-xl p-4">
      <div
        onClick={() => {
          localStorage.setItem(
            "contentOwner",
            JSON.stringify({ id: c_owner?.id })
          );
          history.push(`/site/cnt_owner_page?id=${c_owner?.id}&zIx=890`);
        }}
        className="flex items-start gap-4"
      >
        <img
          src={c_owner?.coverImage ? c_owner?.coverImage : imageHolder}
          alt=""
          className="size-20 rounded-lg object-cover"
        />

        <div>
          <h3 className="text-lg/tight font-medium text-gray-900">
            {!c_owner.isCompany
              ? c_owner.contentOwnershipName
                ? c_owner.contentOwnershipName
                : c_owner.username
              : c_owner.companyName}
          </h3>

          <p className="mt-0.5 text-gray-700">
            since {formatDateTime(c_owner?.createdAt)}
          </p>
        </div>
      </div>
    </div>
  );
};

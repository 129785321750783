import { Box, Grid, SimpleGrid } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/react";
// Custom components
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import "./content.css";
import { useHistory, useLocation } from "react-router-dom";
// import { navigateThePage } from "redux.store/navigation/actionCreators";
import imageHolder from "assets/images/imageHolder.png";

import {
  useGetContentQuery,
  useMyContentEventsListQuery,
} from "generated/graphql";
import Hosting from "./components/Hosting";
import { formatDateTime } from "../helpers";
import Information from "./components/Information";
import Banner from "../marketplace/components/Banner";
import BannerSide from "../marketplace/components/BannerSide";
// Custom components

export default function Content() {
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // console.log(location.state);
  const contentIDd = queryParams.get("id");
  // Convert the string back to an object

  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );
  //#Check remove any
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const contentData = useGetContentQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      getContentInput: {
        contentId: contentIDd,
      },
    },
  });

  const activeOn = useMyContentEventsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      getMyBandaEventsInput: {
        contentId: contentIDd,
        page: 1,
        pageSize: 10,
        // filterQuery,
      },
    },
  });

  console.log("activeOn");
  console.log(activeOn);
  console.log("activeOn");
  //console.log(contentData);


  const idExists = carts.some(
    (item: any) => item.id === contentData?.data?.getContent?.id
  );
  const foundCart = carts.find(
    (item: any) => item.id === contentData?.data?.getContent?.id
  );

  return (
    <div className="w-full flex bg-gray-50 justify-center flex-col items-center ">
      <div className="w-full flex max-w-screen-xl flex-col items-center ">
        <div className="w-full  max-w-screen-xl">
          {/*  */}

          {!contentData.loading ? (
            <>
              <>
                {false && (
                  <section
                    className="py-5  pt-12
 bg-white md:py-5  antialiased "
                    style={{
                      backgroundColor: "#10002b",
                      borderRadius: 10,
                      height: "fit-content",
                    }}
                  >
                    <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 xl:gap-16">
                        <div
                          className="shrink-0 max-w-md lg:max-w-lg mx-auto "
                          style={{
                            backgroundColor: "#10002b",
                            borderRadius: 10,
                            marginBottom: 20,
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            w="100%"
                            h="auto"
                            style={{
                              backgroundColor: "#333",
                              borderRadius: 10,
                            }}
                          >
                            <Box
                              w="100%"
                              // h="450px"
                              bgSize="cover"
                              display={"flex"}
                              alignSelf={"center"}
                            >
                              <img
                                src={contentData?.data?.getContent?.coverImage}
                                className={"centerImg"}
                                style={{ borderRadius: 10 }}
                              ></img>
                            </Box>
                          </Box>
                        </div>

                        <div className="mt-6 sm:mt-8 lg:mt-0"></div>
                      </div>
                    </div>
                  </section>
                )}
              </>

              <section className="overflow-hidden bg-gray-50 sm:grid sm:grid-cols-2">
                <div className="p-8 md:p-12 lg:px-16 lg:py-24">
                  <div className="mx-auto max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
                    <h2 className="text-2xl font-bold text-gray-900 md:text-3xl">
                      {contentData?.data?.getContent?.title}
                    </h2>
                    <p
                      onClick={() => {
                        history.push(
                          `/site/cnt_owner_page?id=${contentData?.data?.getContent?.author?.id}&zIx=890`
                        );
                      }}
                      className="text-sm mt-3 font-extrabold text-gray-800  "
                    >
                      content by{" "}
                      {contentData?.data?.getContent?.author?.username}
                    </p>
                    <div className="flex items-center gap-2 mt-2 mt-0">
                      <div className="flex mt-2 items-center gap-1">
                        <svg
                          className="w-5 h-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20.285 6.709a1 1 0 0 1 0 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414L9 16.586l10.293-10.293a1 1 0 0 1 1.414 0z" />
                        </svg>
                      </div>

                      <p className="text-sm font-medium leading-none text-gray-800  hover:text-md ">
                        34 Reservations
                      </p>
                      <a
                        href={contentData?.data?.getContent?.link}
                        className="text-sm font-medium leading-none text-gray-800 underline hover:no-underline hover:text-md "
                      >
                        Link
                      </a>
                    </div>

                   

                    <hr className="my-6 md:my-8 border-gray-200 " />

                    <SimpleGrid
                      style={{
                        marginTop: 20,
                        marginBottom: 30,
                      }}
                      columns={2}
                      gap="20px"
                    >
                      {contentData?.data?.getContent?.verifiedBy !== "None" && (
                        <Information
                          boxShadow={cardShadow}
                          // title="Do you want to BusketFund  or cancel busketfund  bellow input to add it"
                          title="Verifier"
                          value={contentData?.data?.getContent?.verifiedBy}
                        />
                      )}
                      <Information
                        boxShadow={cardShadow}
                        // title="Do you want to BusketFund  or cancel busketfund  bellow input to add it"
                        title="Uploaded"
                        value={formatDateTime(
                          contentData?.data?.getContent?.createdAt
                        )}
                      />
                      <Information
                        boxShadow={cardShadow}
                        title="Language"
                        value={contentData?.data?.getContent?.languages}
                      />
                    </SimpleGrid>

                    <p className="hidden text-gray-800 text-bold  md:mt-4 md:block">

                      {contentData?.data?.getContent?.description}{" "}
                    </p>

                  

                    <div className="mt-4 md:mt-8">
                      {/* <a
                        href="#"
                        className="inline-block rounded bg-emerald-600 px-12 py-3 text-sm font-medium text-gray-800 transition hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-yellow-400"
                      >
                        Get Started Today
                      </a> */}
                      <div className="mt-6 sm:gap-4 sm:items-center sm:flex sm:mt-8">
                      <a
                        onClick={() => {
                          // addToCart();
                          if (!user.loginStatus) {
                            history.push({
                              pathname: "/auth/sign-in",
                            });

                            return;
                          }
                          if (idExists) {
                            localStorage.setItem(
                              "reservationDataContent",
                              JSON.stringify({
                                reservationData: foundCart,
                                update: true,
                              })
                            );

                            localStorage.setItem(
                              "contentItem",
                              JSON.stringify({
                                content: contentData?.data?.getContent,
                                update: true,
                              })
                            );
                          } else {
                            localStorage.setItem(
                              "contentItem",
                              JSON.stringify({
                                content: contentData?.data?.getContent,
                                update: false,
                              })
                            );
                          }

                          history.push(
                            `/site/reserve_order?id=${contentData?.data?.getContent?.id}&update=${idExists}`
                          );
                        }}
                        className="text-white mt-4 sm:mt-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none  flex items-center justify-center"
                        role="button"
                      >
                        <svg
                          className="w-5 h-5 -ms-2 me-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 4h1.5L8 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm.75-3H7.5M11 7H6.312M17 4v6m-3-3h6"
                          />
                        </svg>
                        Spread to  local communities
                      </a>
                    </div>
                    </div>


                  </div>
                </div>

                <img
                  alt=""
                  src={contentData?.data?.getContent?.coverImage ? contentData?.data?.getContent?.coverImage : imageHolder}
                  className=" w-full object-cover sm:h-full"
                />

                {/* <img
                              src={contentData?.data?.getContent?.coverImage}
                              className={"centerImg"}
                              style={{ borderRadius: 10 }}
                            ></img> */}

                {false && (
                  <div
                    className="shrink-0 max-w-md lg:max-w-lg mx-auto "
                    style={{
                      backgroundColor: "#10002b",
                      borderRadius: 10,
                      marginBottom: 20,
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="100%"
                      h="auto"
                      style={{ backgroundColor: "#333", borderRadius: 10 }}
                    >
                      <Box
                        w="100%"
                        // h="450px"
                        bgSize="cover"
                        display={"flex"}
                        alignSelf={"center"}
                      >
                        <img
                          src={contentData?.data?.getContent?.coverImage}
                          className={"centerImg"}
                          style={{ borderRadius: 10 }}
                        ></img>
                      </Box>
                    </Box>
                  </div>
                )}
              </section>

              {activeOn?.data?.myContentEventsList?.length > 0 && (
                <section
                  className="
 bg-white md:py-0  antialiased mt-5"
                  style={{
                    //backgroundColor:"#10002b",
                    borderRadius: 10,
                    height: "fit-content",
                  }}
                >
                  <div className="max-w-screen-xl mx-auto 2xl:px-0">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 xl:gap-16">
                      <div
                        className="shrink-0 "
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: 10,
                          marginBottom: 20,
                        }}
                      >
                        {/* <Text
            // color={"white"}
            fontWeight="bold"
            fontSize="24px"
            mt="10px"
            mb="4px"
          >Reservations</Text> */}
                        <ul role="list" className="divide-y divide-gray-200">
                          {activeOn?.data?.myContentEventsList?.map(
                            (item: any) => {
                              return (
                                <Hosting
                                  boxShadow={cardShadow}
                                  mb="20px"
                                  image={imageHolder}
                                  ranking="1"
                                  item={item.banda}
                                  dateStart={item?.startDateTime}
                                  dateEnd={item?.endDateTime}
                                  id={item.id}
                                  sponsorImage={item?.sponsor?.coverImage}
                                  link="#"
                                  title={item?.banda?.bandaCode}
                                  sponsorId={item?.sponsor?.id}
                                  category={
                                    item?.sponsor?.anonymous
                                      ? "anonymous"
                                      : item?.sponsor?.isCompany
                                      ? item?.sponsor?.companyName.trim() !== ""
                                        ? item?.sponsor?.companyName
                                        : "anonymous"
                                      : item?.sponsor?.sponsorName.trim() !== ""
                                      ? item?.sponsor?.sponsorName
                                      : "anonymous"
                                  }
                                  sponsorName={
                                    item?.sponsor?.anonymous === true
                                      ? "anonymous"
                                      : item?.sponsor?.isCompany
                                      ? item?.sponsor?.companyName.trim() !== ""
                                        ? item?.sponsor?.companyName
                                        : "anonymous"
                                      : item?.sponsor?.sponsorName.trim() !== ""
                                      ? item?.sponsor?.sponsorName
                                      : "anonymous"
                                  }
                                  home={"Barcelona"}
                                  away={"Napoli"}
                                />
                              );
                            }
                          )}
                        </ul>
                      </div>

                      <div className="mt-6 sm:mt-8 lg:mt-0">
                        <Grid
                          mb={3}
                          templateColumns={{
                            base: "1fr",
                            lg: "repeat(2, 1fr)",
                            //  "2xl": "1.34fr 1.62fr 1fr",
                          }}
                          gap={{ base: "10px", xl: "10px" }}
                        >
                          <Banner />
                          <BannerSide />
                          <Banner />
                          <BannerSide />
                        </Grid>
                        <Banner />
                        <div className="mt-2" />
                        <BannerSide />
                        <div className="mt-2" />
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </>
          ) : (
            <div
              className="flex flex-col items-start space-y-3"
              style={{ marginTop: 200 }}
            >
              <p className="h-20 w-1/2 mt-5 bg-gray-200 rounded-lg animate-gradient-x"></p>
              <p className="h-4 mt-5 bg-gray-200 rounded-full animate-gradient-x"></p>

              <p className="h-4 w-1/2 mt-5 bg-gray-200 rounded-full animate-gradient-x"></p>
              <p className="h-4 w-1/2 mt-5 bg-gray-200 rounded-full animate-gradient-x"></p>

              <ul className="mt-5 space-y-3 w-full">
                <li className="w-full h-4 bg-gray-200 rounded-full animate-gradient-x"></li>
                <li className="w-full h-4 bg-gray-200 rounded-full animate-gradient-x"></li>
                <li className="w-full h-4 bg-gray-200 rounded-full animate-gradient-x"></li>
                <li className="w-full h-4 bg-gray-200 rounded-full animate-gradient-x"></li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

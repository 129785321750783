import React, { useState } from "react";

const VideoPlayer = () => {
  const videoUrls = [
    "https://www.youtube.com/embed/p4qvFDadxfw?autoplay=1&mute=1",
    "https://www.youtube.com/embed/p4qvFDadxfw?autoplay=1&mute=1",
    "https://www.youtube.com/embed/p4qvFDadxfw?autoplay=1&mute=1", 
  ];

  // State to track the current video index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Next button handler
  const goNext = () => {
    if (currentIndex < videoUrls.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Back button handler
  const goBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="h-[400px] p-5">
      <div className="w-full h-full rounded-3xl bg-gray-500">
        {/* Embed the video based on the current index */}
        <iframe
          className="w-full rounded-3xl h-full max-w-full"
          src={videoUrls[currentIndex]}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title="Video Player"
        ></iframe>
      </div>

      {/* Navigation buttons */}
      <div className="mt-4 flex justify-between">
        <button
          onClick={goBack}
          disabled={currentIndex === 0}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:bg-gray-400"
        >
          Back
        </button>
        <button
          onClick={goNext}
          disabled={currentIndex === videoUrls.length - 1}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:bg-gray-400"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default VideoPlayer;

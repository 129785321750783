import * as React from "react";
import "./BandaItem.css";
import {
  Link,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import imageHolder from "assets/images/imageHolder.png";
import { useHistory } from "react-router-dom";
import Information from "views/site/profileSponsor/components/Information";

type Props = {
  banda: any;
  removeCart: (banda: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
  setBanda?: (details: any) => void;
  ongezaKikundi: (value: boolean) => void;
};

export const BandaItem: React.FC<Props> = ({
  banda,
  ongezaKikundi,
  setBanda,
}) => {
  const history = useHistory();

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );


  console.log(banda);

  const funct = () => {
    setBanda(banda);
    ongezaKikundi(true);
  };

  return (
    <div>
      <a
        
        className="relative block bg-white overflow-hidden rounded-lg border border-gray-100 p-4 sm:p-6 lg:p-8"
      >
        <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>

        <div className="sm:flex sm:justify-between sm:gap-4">
          <div >
            <h3  onClick={() => {
          localStorage.setItem("bandaItem", JSON.stringify(banda));
          history.push(`/site/banda_details?id=${banda?.id}&zIx=890`);
        }}
        className="text-lg font-bold text-gray-900 sm:text-xl">
              {banda?.bandaName}
            </h3>

            <p onClick={() => {
          localStorage.setItem("bandaItem", JSON.stringify(banda));
          history.push(`/site/banda_details?id=${banda?.id}&zIx=890`);
        }} className="mt-1 text-xs font-medium text-gray-600">
              {banda.bandaCode}
            </p>
          </div>

          <div onClick={() => {
          localStorage.setItem("bandaItem", JSON.stringify(banda));
          history.push(`/site/banda_details?id=${banda?.id}&zIx=890`);
        }} className="hidden sm:block sm:shrink-0">
            <img
              alt=""
              src={
                banda?.coverImage
                  ? banda?.coverImage
                  :  imageHolder              }
              className="size-16 rounded-lg object-cover shadow-sm"
            />
          </div>
        </div>

        <div className="mt-4">
          {/* <p className="text-pretty text-sm text-gray-500">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. At velit
            illum provident a, ipsa maiores deleniti consectetur nobis et eaque.
          </p> */}
          <Link
            // bg="#003459"
            color="green.400"
            _hover={{ color: "black" }}
            // _active={{ bg: "#f4f4f4" }}
            // _focus={{ bg: "#f4f4f4" }}
            fontWeight="500"
            fontSize="14px"
            // alignSelf={"end"}
            variant={"link"}
            // py="30px"
            // width={"50px"}
            mt={"0px"}
            // px="47px"
            // me="38px"
            // href={"https://sensa.nbs.go.tz/publications"}
            // href={"https://bandagov.vercel.app/dashboard"}
            // target="_blank" // Opens the link in a new tab
            onClick={() => {
              funct();
            }}
            rel="noopener noreferrer"
          >
            {banda?.region ? banda?.region : 0}
            {banda?.district ? " , " + banda?.district : ""}
            {banda?.ward ? " , " + banda?.ward : ""}
            {banda?.mtaaName ? " , " + banda?.mtaaName : ""}
          </Link>

          <Link
            // bg="#003459"
            color="green.400"
            _hover={{ color: "black" }}
            // _active={{ bg: "#f4f4f4" }}
            // _focus={{ bg: "#f4f4f4" }}
            fontWeight="500"
            fontSize="14px"
            // alignSelf={"end"}
            variant={"link"}
            // py="30px"
            // width={"50px"}
            mt={"0px"}
            // px="47px"
            // me="38px"
            // href={"https://sensa.nbs.go.tz/publications"}
            // target="_blank" // Opens the link in a new tab
            onClick={() => {
              funct();
            }}
            rel="noopener noreferrer"
          >{" "}{banda?.physicalResidency ? banda?.physicalResidency : ""}
          </Link>
        </div>

        <dl className="mt-6 flex gap-4 sm:gap-6">
          <div className="flex flex-col-reverse">
            <dt className="text-sm font-medium text-gray-600">Published</dt>
            <dd className="text-xs text-gray-500">31st June, 2021</dd>
          </div>

          <div className="flex flex-col-reverse">
            <dt className="text-sm font-medium text-gray-600">Reading time</dt>
            <dd className="text-xs text-gray-500">3 minute</dd>
          </div>
        </dl>

        <SimpleGrid
          style={{
            marginTop: 20,
            marginBottom: 30,
          }}
          // columns={5}
          minChildWidth={"100px"}
          gap="10px"
        >
          {banda?.capacity && (
            <Information
              boxShadow={cardShadow}
              title="capacity"
              value={banda?.capacity}
            />
          )}
          {banda?.tvSize && (
            <Information
              boxShadow={cardShadow}
              title="Screens"
              value={
                banda?.tvSize
                  ? `${JSON.parse(banda?.tvSize)?.length} Size ${JSON.parse(
                      banda?.tvSize
                    )?.toString()}`
                  : ""
              }
            />
          )}
          {banda?.decoders && (
            <Information
              boxShadow={cardShadow}
              title="Decoders"
              value={
                banda?.decoders ? JSON.parse(banda?.decoders).toString() : ""
              }
              // value={"banda?.decoders ? JSON.parse(banda?.decoders).toString():"}
            />
          )}
          {banda?.eventsFunds && (
            <Information
              boxShadow={cardShadow}
              title="Reservations"
              value={`${banda?.eventsFunds.length} ${
                banda?.eventsFunds.length > 12 ? "+" : ""
              }`}
            />
          )}
          {banda?.bandaSize && (
            <Information
              boxShadow={cardShadow}
              title="Size"
              value={`${banda?.bandaSize}`}
            />
          )}
          {banda?.typeOfSeat && (
            <Information
              boxShadow={cardShadow}
              title="Seat type"
              value={`${banda?.typeOfSeat}`}
            />
          )}
        </SimpleGrid>
        <div className="absolute top-4 right-4 p-2 bg-green-500 text-white rounded-full shadow-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            fillRule="evenodd"
            d="M8.293 10.293a1 1 0 011.414 0l2 2 4-4a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      </a>
    </div>
  );
};

import * as React from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import "./Cart.css";
import { addCart } from "../../redux.store/cart/actionCreators";
import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import {
  IoImageOutline,
} from "react-icons/io5";

import imageHolder from "assets/images/imageHolder.png";
import { useHistory } from "react-router-dom";

type Props = {
  cart: any;
  removeCart: (cart: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
};

export const CartAdd: React.FC<Props> = ({
  cart,
  selected,
  removeCart,
  handleShow,
}) => {
  const history = useHistory();

 
  const textColor = useColorModeValue("navy.700", "white");

  const textColorBid = useColorModeValue("brand.500", "white");
 

  return (
    <div>






      <a  onClick={() => {
                // console.log(cart);
                // console.log(cart);
                // console.log(cart);
                localStorage.setItem("cartPromo", JSON.stringify(cart));
                //  const history = useHistory();

                history.push(`/site/events?id=${cart?.id}&update=${true}`);

              }} className="block rounded-lg p-4 shadow-sm shadow-indigo-100">
  <img
    alt=""
    src={cart?.coverImage ? cart?.coverImage : imageHolder}
    className="h-56 w-full rounded-md object-cover"
  />

  <div className="mt-2">
    <dl>
      <div>
        <dt className="sr-only">{cart.name}</dt>

        <dd className="text-sm text-gray-500">Duration: 1 min</dd>
      </div>

      <div>
        <dt className="sr-only">{cart.owner}</dt>

        <dd className="font-medium">{cart?.title}</dd>
      </div>
    </dl>

    <div className="mt-6 flex items-center gap-8 text-xs">
      <div className="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
        <svg
          className="size-4 text-indigo-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
          />
        </svg>

        <div className="mt-1.5 sm:mt-0">
          <p className="text-gray-500">Hosted</p>

          <p className="font-medium">2 events</p>
        </div>
      </div>

      <div className="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
        <svg
          className="size-4 text-indigo-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
          />
        </svg>

        <div className="mt-1.5 sm:mt-0">
          <p className="text-gray-500">Regions</p>

          <p className="font-medium">10 regions</p>
        </div>
      </div>

      <div className="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
        <svg
          className="size-4 text-indigo-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
          />
        </svg>

        <div className="mt-1.5 sm:mt-0">
          <p className="text-gray-500">Population</p>

          <p className="font-medium">10K</p>
        </div>
      </div>
    </div>
  </div>
</a>

    </div>
   
  );

 
};

// Chakra imports
import { Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function BannerSide() {
  // Chakra Color Mode
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const history = useHistory();
  return (
    <Flex
      direction="column"
      backgroundColor="#393a10"
      py={{ base: "16px", md: "48px" }}
      px={{ base: "16px", md: "48px" }}
      borderRadius="8px"
      boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
      align="center"
      justify="center"
      textAlign="center"
    >
      <Text fontSize="sm" color="#ffffff" fontWeight="500" mb="4px">
      Sponsored
      </Text>
      <Text
        fontSize={{ base: "24px", md: "32px" }}
        color="#ffffff"
        mb="12px"
        fontWeight="700"
        lineHeight={{ base: "32px", md: "40px" }}
      >
        Ruge Mutahaba Foundation
      </Text>
      <Text
        fontSize={{ base: "md", md: "lg" }}
        color="#ffffff"
        fontWeight="400"
        lineHeight="24px"
      >
        Valuable sponsors
      </Text>
    </Flex>
  );
}

import { useState } from "react";
import {
  Box,
  Flex,
  Grid,
  Text,
  Button,
  useColorModeValue,
  SimpleGrid,
  Card,
  Select,
  Input,
  Icon,
} from "@chakra-ui/react";
import Pic1 from "assets/images/imageHolder.png";
import Pic2 from "assets/images/imageHolder.png";
import Pic3 from "assets/images/imageHolder.png";
import Pic4 from "assets/images/haki.jpg";
import Pic5 from "assets/images/imageHolder.png";
import Pic6 from "assets/images/imageHolder.png";
import { removeCart } from "redux.store/cart/actionCreators";
import { shallowEqual, useSelector } from "react-redux";
import { Sponsor, useSponsorsQuery } from "generated/graphql";
import { SponsorItemm } from "components_app/SponsorItemm/SponsorItemm";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import Banner from "./components/Banner";
import BannerSide from "./components/BannerSide";

export const filtersLabels = [
  { mode: "Technology", label: "Technology" },
  { mode: "Finance", label: "Finance" },
  { mode: "Healthcare", label: "Afya" },
  { mode: "Manufacturing", label: "Manufacturing" },
  { mode: "Retail", label: "Retail" },
  { mode: "Hospitality and Tourism", label: "Hospitality and Tourism" },
  { mode: "Energy", label: "Energy" },
  {
    mode: "Transportation and Logistics",
    label: "Transportation and Logistics",
  },
  { mode: "Real Estate", label: "Real Estate" },
  { mode: "Education", label: "Education" },
  { mode: "Entertainment and Media", label: "Entertainment and Media" },
  { mode: "Agriculture", label: "Agriculture" },
  {
    mode: "Government and Public Sector",
    label: "Government and Public Sector",
  },
  { mode: "Construction", label: "Construction" },
  { mode: "Professional Services", label: "Professional Services" },
  { mode: "Mining", label: "Mining" },
  { mode: "Automotive", label: "Automotive" },
  { mode: "Telecommunications", label: "Telecommunications" },
  { mode: "Sports", label: "Sports" },
  { mode: "Biotechnology", label: "Biotechnology" },
  { mode: "Pharmaceuticals", label: "Pharmaceuticals" },
  { mode: "Fashion and Apparel", label: "Fashion and Apparel" },
  { mode: "Food and Beverage", label: "Food and Beverage" },
  {
    mode: "Information Technology (IT) Services",
    label: "Information Technology (IT) Services",
  },
  { mode: "Legal Services", label: "Legal Services" },
  { mode: "Marketing and Advertising", label: "Marketing and Advertising" },
  { mode: "Consumer Goods Services", label: "Consumer Goods Services" },
  {
    mode: "Advisory and Financial Services",
    label: "Advisory and Financial Services",
  },
  { mode: "Wholesale", label: "Wholesale" },
  { mode: "Administration", label: "Administration" },
  {
    mode: "Business Support and Waste Management Services",
    label: "Business Support and Waste Management Services",
  },
  {
    mode: "Other Services (except Public Administration)",
    label: "Other Services (except Public Administration)",
  },
  { mode: "Music", label: "Music" },
  { mode: "Other", label: "Other" },
];

export const sampleData2 = [
  {
    id: "asb",
    images: ["../../../assets/images/content.png", "khg"],
    image: Pic1,
    videos: ["hjgj", "fjjj"],
    title: "Elimu ya Afya",
    name: "Mazingira",
    type: "CONTENT",
    owner: "By Balozi ya Ufaransa",
    description: "descriptiongfch",
    body: "Afya ya uzazi, akili na mazingira",
    othersMetaData: ["tracking data"],
    price: 0,
    currency: "gj",
    discount: "5%",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "ansd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic2,
    videos: ["hjgj", "fjjj"],
    title: "Semina ya vijana",
    owner: "Haki Elimu",
    type: "CONTENT",
    description: "descriptiongfch",
    body: "Vijana na kujituma",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "an90sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic3,
    videos: ["hjgj", "fjjj"],
    title: "Ujasiliamali",
    owner: "Wizara ya Afya",
    type: "CONTENT",
    description: "descriptiongfch",
    body: "Kuhusu kujizalishia kipato",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2nsdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic4,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",
    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2n087sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic5,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",
    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2n875sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic6,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",
    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
];

export default function ExploreSponsors() {
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(9);

  const totalPages = 10; // You can set this to the total number of pages available

  // Handle the previous page
  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Handle the next page
  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  // Handle page number click
  const handlePageClick = (pageNum:number) => {
    setPage(pageNum);
  };


  const [category, setCategory] = useState("all");
  const handleChange = (e: any) => {
    setCategory(e.target.value);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };



  const { loading, data } = useSponsorsQuery({
    fetchPolicy: "network-only",
    variables: {
      getSponsorsInput: {
        page,
        pageSize,
        category,
        searchQuery: searchQuery,
      },
    },
  });

  const [show, setShow] = useState(false);
  const [filtering, setFiltering] = useState(false);

  const handleShow = () => setShow(true);

  return (
    <div className="w-full flex bg-gray-50 justify-center flex-col items-center ">
      <div className="w-full flex max-w-screen-xl flex-col items-center ">
          <Grid
            mb={6}
            templateColumns={{
              base: "1fr",
              lg: "repeat(2, 1fr)",
              //  "2xl": "1.34fr 1.62fr 1fr",
            }}
            className="w-full max-w-screen-xl"

            gap={6}
          >
            <Banner />
            <BannerSide />
          </Grid>

          {!loading ? (
            <>
              {" "}
              <Grid
                mb={6}
                gridTemplateColumns={{
                  xl: "repeat(1, 1fr)",
                  // "2xl": "1fr 0.46fr"
                }}
                className="w-full max-w-screen-xl"

                gap={6}
              >
                <Card
                  mb={{ base: "0px", "2xl": "20px" }}
                  borderRadius="8px"
                  p={6}
                  bg="#37005d"
                >
                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text
                      fontSize={{ base: "14px", md: "24px" }}
                      color="#eee5e9"
                      fontWeight="700"
                    >
                      Search
                    </Text>
                    <Button
                      bg="inherit"
                      m={0}
                      _hover={{ bg: "#1f0033" }}
                      onClick={() => setFiltering(!filtering)}
                    >
                      <Icon
                        as={filtering ? MdArrowDownward : MdArrowUpward}
                        width="20px"
                        height="20px"
                        color="white"
                      />
                    </Button>
                  </Flex>

                  {filtering && (
                    <>
                      <Select
                        name="category"
                        color="#eee5e9"
                        borderColor="#6b00b3"
                        _hover={{ borderColor: "#8a00e6" }}
                        value={category}
                        onChange={handleChange}
                        mt={4}
                        mb={4}
                      >
                        {[{ mode: "all", label: "All" }, ...filtersLabels].map(
                          (cat: any) => (
                            <option key={cat.mode} value={cat.mode}>
                              {cat.label}
                            </option>
                          )
                        )}
                      </Select>

                      <Input
                        value={searchQuery}
                        onChange={handleSearchChange}
                        name="Search"
                        color="#eee5e9"
                        borderColor="#6b00b3"
                        _hover={{ borderColor: "#8a00e6" }}
                        placeholder="Search here by name"
                      />
                    </>
                  )}
                </Card>
              </Grid>
              <Grid
                mb={6}
                gridTemplateColumns={{
                  xl: "repeat(1, 1fr)",
                  // "2xl": "1fr 0.46fr"
                }}
                gap={6}
                className="w-full max-w-screen-xl"

              >
                <Flex
                  direction="column"
                  gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
                >
                  <SimpleGrid mt={6} columns={{ base: 1, md: 3 }} gap={6}>
                    {data?.sponsors?.map((sponsor: any) => {
                      const existingCart = carts.find(
                        (item: ICart) => item.id === sponsor.id
                      );
                      return (
                        // !sponsor?.anonymous &&
                        // sponsor?.sponsorName?.trim() !== "" &&
                        // sponsor?.companyName?.trim() !== "" &&
                        <SponsorItemm
                          key={sponsor.id}
                          sponsor={sponsor}
                          removeCart={removeCart}
                          selected={existingCart}
                          handleShow={handleShow}
                        />
                      );
                    })}
                  </SimpleGrid>
                </Flex>
                <ol className="my-6 mt-12  p-3 flex justify-center gap-1 text-md font-medium">
      {/* Previous Page Button */}
      <li>
        <button
          onClick={handlePrev}
          className="inline-flex size-14 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
        >
          <span className="sr-only">Prev Page</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </li>

      {/* Page Numbers */}
      {[...Array(totalPages).keys()].map((pageNum) => (
        <li key={pageNum}>
          <button
            onClick={() => handlePageClick(pageNum + 1)}
            className={`block size-14 rounded border text-center leading-8 ${
              page === pageNum + 1
                ? 'border-blue-600 bg-blue-600 text-white'
                : 'border-gray-100 bg-white text-gray-900'
            }`}
          >
            {pageNum + 1}
          </button>
        </li>
      ))}

      {/* Next Page Button */}
      <li>
        <button
          onClick={handleNext}
          className="inline-flex size-14 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
        >
          <span className="sr-only">Next Page</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </li>
    </ol>
              </Grid>
            </>
          ) : (
            <>
              {" "}
              <div className="flex justify-center mt-20 h-screen">
                <div
                  className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  );
}

import * as React from "react";
import { shallowEqual, useSelector } from "react-redux";
import "./Cart.css";
import imageHolder from "assets/images/imageHolder.png";
import { useHistory } from "react-router-dom";

type Props = {
  cart: any;
  removeCart: (cart: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
};

export const Cart: React.FC<Props> = ({
  cart,
}) => {
  const history = useHistory();

  
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );


  const idExists = carts.some((item: any) => item.id === cart.id);

  return (
    <div>
      <article  
      onClick={() => {
                if (
                  user.sponsorStatus === "sponsor" &&
                  !(user.mode === "banda")
                ) {
                  if (user.loginStatus || true) {
                    localStorage.setItem("contentItem", JSON.stringify(cart));
                    localStorage.setItem(
                      "activeCart",
                      JSON.stringify({ item: cart, isPromo: false })
                    );

                    history.push(`/site/content?id=${cart?.id}&zIx=890`, {
                      itemData: "qqsad",
                      update: true,
                      state: {
                        itemId: cart?.id,
                        update: idExists, //exist now update
                        isPromo: false,
                      },
                    });
                  } else {
                    history.push("/auth/sign-in");
                  }
                } else {
                  localStorage.setItem("contentItem", JSON.stringify(cart));

                  history.push(`/site/content?id=${cart?.id}&zIx=890`, {
                    itemData: cart.id,
                    update: idExists, //exist now update
                  });
                }
              }}
              className="relative overflow-hidden rounded-lg shadow transition hover:shadow-md ">
        <img
          alt=""
          src={cart?.coverImage ? cart?.coverImage : imageHolder}
          className="absolute inset-0 h-full w-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-50 hover:opacity-40"></div>
        <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64">
          <div className="p-4 sm:p-6">
            <p className="block text-sm text-white">
              {" "}
              {cart?.author?.username}
            </p>
            <p className="block text-sm text-white"> 10th Oct 2022</p>

            <a
              onClick={() => {
                if (
                  user.sponsorStatus === "sponsor" &&
                  !(user.mode === "banda")
                ) {
                  if (user.loginStatus || true) {
                    localStorage.setItem("contentItem", JSON.stringify(cart));
                    localStorage.setItem(
                      "activeCart",
                      JSON.stringify({ item: cart, isPromo: false })
                    );

                    history.push(`/site/content?id=${cart?.id}&zIx=890`, {
                      itemData: "qqsad",
                      update: true,
                      state: {
                        itemId: cart?.id,
                        update: idExists, //exist now update
                        isPromo: false,
                      },
                    });
                  } else {
                    history.push("/auth/sign-in");
                  }
                } else {
                  localStorage.setItem("contentItem", JSON.stringify(cart));

                  history.push(`/site/content?id=${cart?.id}&zIx=890`, {
                    itemData: cart.id,
                    update: idExists, //exist now update
                  });
                }
              }}
            >
              <h3 className="mt-0.5 text-xl text-white"> {cart?.title}</h3>
            </a>

            <p className="mt-2 line-clamp-3 text-md text-white">
              {cart?.events
                ? `In  ${cart?.events.length}${
                    cart?.events.length > 0 ? "+" : ""
                  }  ${cart?.events.length > 0 ? "events" : "event"}`
                : ""}
            </p>
          </div>
        </div>

        <div className="absolute top-4 right-4 p-2 bg-green-500 text-white rounded-full shadow-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            fillRule="evenodd"
            d="M8.293 10.293a1 1 0 011.414 0l2 2 4-4a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      </article>
      
    </div>
  );
};

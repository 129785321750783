// Chakra imports
import {
  Box,
  Button,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Text,
  Image,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { error } from "console";
import AWS from "aws-sdk";
import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import "react-image-crop/dist/ReactCrop.css";
import imageHolder from "assets/images/imageHolder.png";

import {
  ContentType,
  useCreateContentMutation,
  useRegistrationBandaMutation,
} from "generated/graphql";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { filtersLabels } from "views/site/marketplace";
import Profile from "./components/components/Profile";
import PencilIcon from "./components/components/PencilIcon";
import Modal from "./components/components/Modal";
const CROP_AREA_ASPECT = 4 / 5;

export interface FormData {
  title: string;
  description: string;
  link: string;

  verifiedBy: string;
  duration: string;

  price: number;
  currency: string;

  contentType: string;
  languages: string;
  categories: string;
}

interface FormErrors {
  [key: string]: string;
}

// Assets
export default function UploadAdd(props: { [x: string]: any }) {
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);

  const { ...rest } = props;
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const validateDescription = (description: string) => {
    const textRegex = /^(.+)$/;
    if (!description.trim()) {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "description is required",
      // }));
    } else if (!textRegex.test(description)) {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "Wrong Residency",
      // }));
    } else {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "",
      // }));
    }
  };

  const validateTitle = (title: string) => {
    const textRegex = /^(.+)$/;
    if (!title.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is required",
      }));
    } else if (!textRegex.test(title)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "",
      }));
    }
  };

  const validateLink = (link: string) => {
    const textRegex = /^https:\/\/[^\s/$.?#].[^\s]*$/;

    if (!link.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "link is required",
      }));
    } else if (!textRegex.test(link)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "link is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "",
      }));
    }
  };

  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    link: "",
    contentType: "",
    languages: "",
    categories: "",
    price: 0,
    currency: "Tsh",
    verifiedBy: "",
    duration: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [uploadedSuccess, setUploadedSuccess] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      responseError: "",
    }));

    // Validate the field based on its name
    switch (name) {
      case "title":
        validateTitle(value);
        break;
      case "description":
        validateDescription(value);
        break;
      case "link":
        validateLink(value);
        break;
      default:
        break;
    }
  };

  const [createContent] = useCreateContentMutation();
  const [load, setLoad] = useState(false);

  const handleSubmit = async () => {
    setLoad(true);
    console.log(formData);
    validateTitle(formData.title);
    validateDescription(formData.description);
    validateLink(formData.link);

    //  return

    let imageUrll;

    try {
      if (errors.title && errors.description && errors.link) {
        setLoad(false);
        return;
      }

      try {
        imageUrll = await uploadFile(imageFile);
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Image Upload failed",
        }));
        setLoad(false);
        return;
      }

      let uploadingData = {
        coverImage: imageUrll, //Url link
        media: formData.link,
        contentType: formData.contentType === "PROMO" ? "PROMO" : "PROMO",
        userId: user.id,
        title: formData.title,
        description: formData.description,
        link: formData.link,
        amount: formData.price,
        currency: "Tsh", //formData.currency,
        languages: formData.languages,
        categorySelected: formData.categories,
        verifiedBy: formData.verifiedBy,
        duration: formData.duration,
      };
      const response = await createContent({
        variables: {
          createContentInput: uploadingData,
        },
      });

      if (
        response.data &&
        response.data.createContent &&
        !response.data.createContent.status
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Upload failed",
        }));
        setLoad(false);
        return;
      } else {
        console.log(response.data.createContent);
        setUploadedSuccess(true);
        setFormData({
          title: "",
          description: "",
          link: "",
          contentType: "",
          languages: "",
          price: 0,
          currency: "Tsh",
          categories: "",
          verifiedBy: "",
          duration: "",
        });
        handleClearImage();
        setImageUrl("");
        avatarUrl.current = imageHolder;

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
      }
      setLoad(false);

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
      setLoad(false);
    }
  };

  const handleSubmit2 = async () => {
    console.log(formData);
    validateTitle(formData.title);
    validateDescription(formData.description);
    validateLink(formData.link);
    //  return

    if (errors.title && errors.description && errors.link) {
      return;
    }

    // {
    //   userId: String
    //   description: String
    //   coverImage: String
    //   title: String
    //   media: String
    //   published: Boolean
    //   categorySelected: String
    //   contentType: ContentType
    //   link: String
    // }

    let uploadingData = {
      coverImage: " ", //Url link
      media: formData.link,
      contentType: formData.contentType === "PROMO" ? "PROMO" : "PROMO",
      userId: user.id,
      title: formData.title,
      description: formData.description,
      link: formData.link,
      languages: formData.languages,
      categorySelected: formData.categories,
    };

    try {
      const response = await createContent({
        variables: {
          createContentInput: uploadingData,
        },
      });

      if (
        response.data &&
        response.data.createContent &&
        !response.data.createContent.status
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Registration failed",
        }));
        return;
      } else {
        console.log(response.data.createContent);
        setUploadedSuccess(true);
        setFormData({
          title: "",
          description: "",
          link: "",
          contentType: "",
          languages: "",
          price: 0,
          currency: "Tsh",
          categories: "",
          duration: "",
          verifiedBy: "",
        });
        handleClearImage();
        updateAvatar(imageHolder);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
      }
      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
    }
  };

  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = document.createElement("img");
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const handleClearImage = () => {
    setImageData(null);
    setImageFile(null);
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear file input value
    }
  };

  const avatarUrl = useRef(imageHolder);
  const [modalOpen, setModalOpen] = useState(false);

  const updateAvatar = (imgSrc: string) => {
    // Update the avatar URL reference
    avatarUrl.current = imgSrc;

    // Convert the updated avatar URL to a File object
    fetch(imgSrc)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a File object with the blob and assign it to state
        const file = new File([blob], "avatar.jpg", { type: "image/jpeg" });
        setImageFile(file);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  const uploadFile = async (image: File | null) => {
    if (!image) {
      console.error("No image to upload");
      return;
    }

    const S3_BUCKET = "zutraxbanda";
    const REGION = "eu-north-1";
    const currentTime = new Date().getTime();

    AWS.config.update({
      accessKeyId: "AKIAXQIQAKXLYGWR3YHM",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const key = `itemid_manage_${user.id}/${currentTime}_${image.name}`;
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: image,
    };

    const upload = s3.upload(params).promise();

    try {
      const data = await upload;
      console.log(data);
      const imageUrl = `https://${key}`;
      setImageUrl(imageUrl);
      return data.Location;
      // alert("File uploaded successfully.");
    } catch (err) {
      setImageUrl("");

      console.error("Error uploading file:", err);
      // alert("Error uploading file. Please try again later.");
      return "";
    }
  };
  if (load) {
    return (
      <Card mt={"120px"}>
        {" "}
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Loading ...{" "}
        </Text>
      </Card>
    );
  }

  return (
    <div className="w-full flex bg-gray-50 justify-center flex-col items-center ">
      <div className="w-full flex max-w-screen-xl flex-col items-center ">
        <div className="w-full  max-w-screen-xl">
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
            gap="20px"
            mb="20px"
          >
            <Card>
              <Text
                color={textColorPrimary}
                fontWeight="bold"
                fontSize="2xl"
                mt="10px"
                mb="4px"
              >
                {"Advertisement"}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize="md"
                me="26px"
                mb="40px"
              >
                Adhere to rules and regulations, terms and conditions, and
                respect rights related to content ownership.
              </Text>

              <div className="py-2 px-1  lg:py-2">
                {/* <h2 className="mb-4 text-xl font-bold text-gray-900 ">Add a new product</h2> */}
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="title"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        value={formData.title}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Title"
                        required
                      />
                      {errors.title && (
                        <p className="text-red-500 text-sm">{errors.title}</p>
                      )}
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="verifiedBy"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Verified by
                      </label>
                      <input
                        type="text"
                        name="verifiedBy"
                        id="verifiedBy"
                        value={formData.verifiedBy}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Verified by"
                        required
                      />
                      {errors.verifiedBy && (
                        <p className="text-red-500 text-sm">
                          {errors.verifiedBy}
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="duration"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Duration
                      </label>
                      <input
                        type="number"
                        name="duration"
                        id="duration"
                        value={formData.duration}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Duration in seconds"
                        required
                      />
                      {errors.duration && (
                        <p className="text-red-500 text-sm">
                          {errors.duration}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="categories"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Category
                      </label>
                      <select
                        name="categories"
                        id="categories"
                        value={formData.categories}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                      >
                        {[...filtersLabels].map((cat: any) => (
                          <option key={cat.mode} value={cat.mode}>
                            {cat.label}
                          </option>
                        ))}
                      </select>
                      {errors.categories && (
                        <p className="text-red-500 text-sm">
                          {errors.categories}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="languages"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Languages
                      </label>
                      <select
                        name="languages"
                        id="languages"
                        value={formData.languages}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                      >
                        {[
                          { mode: "English", label: "English" },
                          { mode: "Swahili", label: "Swahili" },
                          {
                            mode: "Swahili and English",
                            label: "Swahili and English",
                          },
                          { mode: "Others", label: "Others" },
                        ].map((cat: any) => (
                          <option key={cat.mode} value={cat.mode}>
                            {cat.label}
                          </option>
                        ))}
                      </select>
                      {errors.category && (
                        <p className="text-red-500 text-sm">
                          {errors.category}
                        </p>
                      )}
                    </div>
                    {/* <div>
              <label htmlFor="price" className="block mb-2 text-sm font-medium text-gray-900 ">Price</label>
              <input type="number" name="price" id="price" value={formData.price} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="Price" required />
              {errors.price && <p className="text-red-500 text-sm">{errors.price}</p>}
            </div> */}
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="description"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Description
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        rows={8}
                        value={formData.description}
                        onChange={handleChange}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                        placeholder="Your description here"
                      ></textarea>
                      {errors.description && (
                        <p className="text-red-500 text-sm">
                          {errors.description}
                        </p>
                      )}
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="link"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Link
                      </label>
                      <input
                        type="text"
                        name="link"
                        id="link"
                        value={formData.link}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Link"
                        required
                      />
                      {errors.link && (
                        <p className="text-red-500 text-sm">{errors.link}</p>
                      )}
                    </div>
                  </div>

                  {/* <button type="submit" className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">
            Add product
          </button> */}
                </form>

                {croppedImage && <Image src={croppedImage} alt="Cropped" />}

                <Box
                  style={{
                    marginTop: 50,
                  }}
                >
                  {/* <FormLabel
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          display="flex"
        >
          Choose Cover Page
        </FormLabel> */}

                  {/* <Card bg={cardShadow} {...rest}>
          <Box
            style={{
              width: 300,
              marginBottom: 30,
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
              Image should be less than 10Mb Png,Jpeg or Jpg
            </Text>
            <Text color={textColorPrimary} fontWeight="500" fontSize="md">
              Choose Cover Page
            </Text>
            <Input
              type="file"
              accept="image/*"
              variant={"filled"}
              ref={inputRef}
              onChange={handleImageChange}
              mb={4}
            />
          </Box>
          {imageData && (
            <>
              <Image
                boxSize="350px"
                objectFit="cover"
                // src='https://bit.ly/dan-abramov'
                src={imageData as string}
                alt="Dan Abramov"
              />
            </>
          )}
        </Card> */}
                </Box>
              </div>
            </Card>

            <Card>
              {/* CROPING IMAGE */}
              <div className=" text-gray-400  ">
                <div className="flex flex-col items-center pt-12">
                  <div className="relative">
                    <img
                      src={avatarUrl.current}
                      alt="Avatar"
                      className="w-[100%] h-[100%]  border-2 "
                      style={{
                        border: "5px solid #d100d1",
                        borderRadius: 8,
                      }}
                    />
                    <button
                      className="absolute -bottom-3 left-0 right-0 m-auto w-fit p-[.35rem] rounded-full bg-gray-800 hover:bg-gray-700 border border-gray-600"
                      title="Change photo"
                      onClick={() => setModalOpen(true)}
                    >
                      <PencilIcon />
                    </button>
                  </div>
                  <h2 className="text-black font-bold mt-6">Add Cover image</h2>
                  <p className="text-gray-500 text-xs mt-2">
                    Image should be less than 10Mb Png,Jpeg or Jpg
                  </p>

                  {!uploadedSuccess ||
                  errors.responseError ||
                  errors.title ||
                  errors.description ||
                  errors.link ? (
                    <Button
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                      colorScheme="green"
                      marginTop="4"
                      style={{
                        width: "30%",
                        alignSelf: "center",
                      }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <>
                      <Card bg={cardShadow} {...rest}>
                        <Box
                          style={{
                            width: 300,
                            marginBottom: 30,
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Text
                            fontWeight="500"
                            color={textColorSecondary}
                            fontSize="sm"
                          >
                            Your content is on review , Please kindly wait for
                            2-24 hrs
                          </Text>
                          <Text
                            color={textColorPrimary}
                            fontWeight="500"
                            fontSize="md"
                          >
                            UPLOADED SUCCESSFULY
                          </Text>
                        </Box>
                        <Button
                          type="submit"
                          onClick={() => {
                            setUploadedSuccess(false);
                            setFormData({
                              title: "",
                              description: "",
                              link: "",
                              contentType: "",
                              languages: "",
                              price: 0,
                              currency: "Tsh",
                              categories: "",
                              duration: "",
                              verifiedBy: "",
                            });
                            setImageData(null);
                            setImageFile(null);
                            handleClearImage();
                          }}
                          colorScheme="blackAlpha"
                          marginTop="4"
                          style={{
                            width: "100%",
                            alignSelf: "center",
                          }}
                        >
                          Upload another New Content
                        </Button>
                      </Card>
                    </>
                  )}
                  {errors.responseError && (
                    <Text style={{ color: "red" }}>Error </Text>
                  )}

                  {/* {imageFile && (
        <div>
          <p>File Name: {imageFile.name}</p>
          <p>File Size: {imageFile.size} bytes</p>
          <p>File Type: {imageFile.type}</p>
        </div>
      )} */}

                  {modalOpen && (
                    <Modal
                      updateAvatar={updateAvatar}
                      closeModal={() => setModalOpen(false)}
                    />
                  )}
                </div>
              </div>
            </Card>
          </SimpleGrid>
        </div>
      </div>
    </div>
  );
}
